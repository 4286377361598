import { Link } from "react-router-dom";
import Style from "./MenuApp.module.scss";

export default function MenuApp() {
  const menu = [
    { link: "/topics", title: "Форум" },
    { link: "/chats", title: "Чаты" },
  ];

  return (
    <ul className={Style.list}>
      {menu.map((elem, i) => (
        <li key={elem.title + i}>
          <Link to={elem.link}>
            <h2 className={Style.title}>{elem.title}</h2>
          </Link>
        </li>
      ))}
    </ul>
  );
}
