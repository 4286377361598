import { URL_SERVER } from "./Constants";
import { Params, Headers } from "./UserApi";

class ChatApi {
  readonly baseUrl: string;
  readonly headers: Headers;
  constructor({ baseUrl, headers }: Params) {
    this.baseUrl = baseUrl;
    this.headers = headers;
  }

  public addNewChat(title: { title: string }) {
    return fetch(this.baseUrl, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(title),
    }).then(this._checkResponse);
  }

  public getAllChats(token: string) {
    return fetch(this.baseUrl, {
      method: "get",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token") || token}`,
        "content-type": "application/json",
      },
    }).then(this._checkResponse);
  }

  public getMessages(number: number, idChat: string) {
    return fetch(`${this.baseUrl}/messages/${number}&${idChat}`, {
      method: "get",
      headers: this.headers,
    }).then(this._checkResponse);
  }

  public getChatId(id: string) {
    return fetch(`${this.baseUrl}/${id}`, {
      method: "GET",
      headers: this.headers,
    }).then(this._checkResponse);
  }

  public deleteChat(id: string) {
    return fetch(`${this.baseUrl}/${id}`, {
      method: "DELETE",
      headers: this.headers,
    }).then(this._checkResponse);
  }

  private _checkResponse = (res: Response) => {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(`Ошибка: ${res.status}`);
  };
}

const chatApi = new ChatApi({
  baseUrl: `${URL_SERVER}/chat`,
  headers: {
    authorization: `Bearer ${localStorage.getItem("token")}`,
    "content-type": "application/json",
  },
});

export { chatApi };
