import Style from "./Alert.module.scss";

type AlertProps = {
  text: string;
  stateAlert: boolean;
};

export default function Alert({ text, stateAlert }: AlertProps) {
  return (
    <div
      className={
        stateAlert
          ? `${Style.conteiner} ${Style.conteiner_on}`
          : `${Style.conteiner}`
      }
    >
      <h1>{text}</h1>
    </div>
  );
}
