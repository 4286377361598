export type AllTown = {
  region: string;
  city: string;
};

export const allTown: AllTown[] = [
  {
    region: "Москва и Московская обл.",
    city: "Москва",
  },
  {
    region: "Москва и Московская обл.",
    city: "Абрамцево",
  },
  {
    region: "Москва и Московская обл.",
    city: "Алабино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Апрелевка",
  },
  {
    region: "Москва и Московская обл.",
    city: "Архангельское",
  },
  {
    region: "Москва и Московская обл.",
    city: "Ашитково",
  },
  {
    region: "Москва и Московская обл.",
    city: "Байконур",
  },
  {
    region: "Москва и Московская обл.",
    city: "Бакшеево",
  },
  {
    region: "Москва и Московская обл.",
    city: "Балашиха",
  },
  {
    region: "Москва и Московская обл.",
    city: "Барыбино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Белоомут",
  },
  {
    region: "Москва и Московская обл.",
    city: "Белые Столбы",
  },
  {
    region: "Москва и Московская обл.",
    city: "Бородино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Бронницы",
  },
  {
    region: "Москва и Московская обл.",
    city: "Быково",
  },
  {
    region: "Москва и Московская обл.",
    city: "Валуево",
  },
  {
    region: "Москва и Московская обл.",
    city: "Вербилки",
  },
  {
    region: "Москва и Московская обл.",
    city: "Верея",
  },
  {
    region: "Москва и Московская обл.",
    city: "Видное",
  },
  {
    region: "Москва и Московская обл.",
    city: "Внуково",
  },
  {
    region: "Москва и Московская обл.",
    city: "Вождь Пролетариата",
  },
  {
    region: "Москва и Московская обл.",
    city: "Волоколамск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Вороново",
  },
  {
    region: "Москва и Московская обл.",
    city: "Воскресенск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Восточный",
  },
  {
    region: "Москва и Московская обл.",
    city: "Востряково",
  },
  {
    region: "Москва и Московская обл.",
    city: "Высоковск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Голицино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Деденево",
  },
  {
    region: "Москва и Московская обл.",
    city: "Дедовск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Джержинский",
  },
  {
    region: "Москва и Московская обл.",
    city: "Дмитров",
  },
  {
    region: "Москва и Московская обл.",
    city: "Долгопрудный",
  },
  {
    region: "Москва и Московская обл.",
    city: "Домодедово",
  },
  {
    region: "Москва и Московская обл.",
    city: "Дорохово",
  },
  {
    region: "Москва и Московская обл.",
    city: "Дрезна",
  },
  {
    region: "Москва и Московская обл.",
    city: "Дубки",
  },
  {
    region: "Москва и Московская обл.",
    city: "Дубна",
  },
  {
    region: "Москва и Московская обл.",
    city: "Егорьевск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Железнодорожный",
  },
  {
    region: "Москва и Московская обл.",
    city: "Жилево",
  },
  {
    region: "Москва и Московская обл.",
    city: "Жуковский",
  },
  {
    region: "Москва и Московская обл.",
    city: "Загорск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Загорянский",
  },
  {
    region: "Москва и Московская обл.",
    city: "Запрудная",
  },
  {
    region: "Москва и Московская обл.",
    city: "Зарайск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Звенигород",
  },
  {
    region: "Москва и Московская обл.",
    city: "Зеленоград",
  },
  {
    region: "Москва и Московская обл.",
    city: "Ивантеевка",
  },
  {
    region: "Москва и Московская обл.",
    city: "Икша",
  },
  {
    region: "Москва и Московская обл.",
    city: "Ильинский",
  },
  {
    region: "Москва и Московская обл.",
    city: "Истра",
  },
  {
    region: "Москва и Московская обл.",
    city: "Калининград",
  },
  {
    region: "Москва и Московская обл.",
    city: "Кашира",
  },
  {
    region: "Москва и Московская обл.",
    city: "Керва",
  },
  {
    region: "Москва и Московская обл.",
    city: "Климовск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Клин",
  },
  {
    region: "Москва и Московская обл.",
    city: "Клязьма",
  },
  {
    region: "Москва и Московская обл.",
    city: "Кожино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Кокошкино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Коломна",
  },
  {
    region: "Москва и Московская обл.",
    city: "Колюбакино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Королев",
  },
  {
    region: "Москва и Московская обл.",
    city: "Косино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Котельники",
  },
  {
    region: "Москва и Московская обл.",
    city: "Красково",
  },
  {
    region: "Москва и Московская обл.",
    city: "Красноармейск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Красногорск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Краснозаводск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Краснознаменск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Красный Ткач",
  },
  {
    region: "Москва и Московская обл.",
    city: "Крюково",
  },
  {
    region: "Москва и Московская обл.",
    city: "Кубинка",
  },
  {
    region: "Москва и Московская обл.",
    city: "Купавна",
  },
  {
    region: "Москва и Московская обл.",
    city: "Куровское",
  },
  {
    region: "Москва и Московская обл.",
    city: "Лесной Городок",
  },
  {
    region: "Москва и Московская обл.",
    city: "Ликино-Дулево",
  },
  {
    region: "Москва и Московская обл.",
    city: "Лобня",
  },
  {
    region: "Москва и Московская обл.",
    city: "Лопатинский",
  },
  {
    region: "Москва и Московская обл.",
    city: "Лосино-Петровский",
  },
  {
    region: "Москва и Московская обл.",
    city: "Лотошино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Лукино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Луховицы",
  },
  {
    region: "Москва и Московская обл.",
    city: "Лыткарино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Львовский",
  },
  {
    region: "Москва и Московская обл.",
    city: "Люберцы",
  },
  {
    region: "Москва и Московская обл.",
    city: "Малаховка",
  },
  {
    region: "Москва и Московская обл.",
    city: "Михайловское",
  },
  {
    region: "Москва и Московская обл.",
    city: "Михнево",
  },
  {
    region: "Москва и Московская обл.",
    city: "Можайск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Монино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Муханово",
  },
  {
    region: "Москва и Московская обл.",
    city: "Мытищи",
  },
  {
    region: "Москва и Московская обл.",
    city: "Нарофоминск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Нахабино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Некрасовка",
  },
  {
    region: "Москва и Московская обл.",
    city: "Немчиновка",
  },
  {
    region: "Москва и Московская обл.",
    city: "Новобратцевский",
  },
  {
    region: "Москва и Московская обл.",
    city: "Новоподрезково",
  },
  {
    region: "Москва и Московская обл.",
    city: "Ногинск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Обухово",
  },
  {
    region: "Москва и Московская обл.",
    city: "Одинцово",
  },
  {
    region: "Москва и Московская обл.",
    city: "Ожерелье",
  },
  {
    region: "Москва и Московская обл.",
    city: "Озеры",
  },
  {
    region: "Москва и Московская обл.",
    city: "Октябрьский",
  },
  {
    region: "Москва и Московская обл.",
    city: "Опалиха",
  },
  {
    region: "Москва и Московская обл.",
    city: "Орехово-Зуево",
  },
  {
    region: "Москва и Московская обл.",
    city: "Павловский Посад",
  },
  {
    region: "Москва и Московская обл.",
    city: "Первомайский",
  },
  {
    region: "Москва и Московская обл.",
    city: "Пески",
  },
  {
    region: "Москва и Московская обл.",
    city: "Пироговский",
  },
  {
    region: "Москва и Московская обл.",
    city: "Подольск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Полушкино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Правдинский",
  },
  {
    region: "Москва и Московская обл.",
    city: "Привокзальный",
  },
  {
    region: "Москва и Московская обл.",
    city: "Пролетарский",
  },
  {
    region: "Москва и Московская обл.",
    city: "Протвино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Пушкино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Пущино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Радовицкий",
  },
  {
    region: "Москва и Московская обл.",
    city: "Раменское",
  },
  {
    region: "Москва и Московская обл.",
    city: "Реутов",
  },
  {
    region: "Москва и Московская обл.",
    city: "Решетниково",
  },
  {
    region: "Москва и Московская обл.",
    city: "Родники",
  },
  {
    region: "Москва и Московская обл.",
    city: "Рошаль",
  },
  {
    region: "Москва и Московская обл.",
    city: "Рублево",
  },
  {
    region: "Москва и Московская обл.",
    city: "Руза",
  },
  {
    region: "Москва и Московская обл.",
    city: "Салтыковка",
  },
  {
    region: "Москва и Московская обл.",
    city: "Северный",
  },
  {
    region: "Москва и Московская обл.",
    city: "Сергиев Посад",
  },
  {
    region: "Москва и Московская обл.",
    city: "Серебряные Пруды",
  },
  {
    region: "Москва и Московская обл.",
    city: "Серпухов",
  },
  {
    region: "Москва и Московская обл.",
    city: "Солнечногорск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Солнцево",
  },
  {
    region: "Москва и Московская обл.",
    city: "Софрино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Старая Купавна",
  },
  {
    region: "Москва и Московская обл.",
    city: "Старбеево",
  },
  {
    region: "Москва и Московская обл.",
    city: "Ступино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Сходня",
  },
  {
    region: "Москва и Московская обл.",
    city: "Талдом",
  },
  {
    region: "Москва и Московская обл.",
    city: "Текстильщик",
  },
  {
    region: "Москва и Московская обл.",
    city: "Темпы",
  },
  {
    region: "Москва и Московская обл.",
    city: "Тишково",
  },
  {
    region: "Москва и Московская обл.",
    city: "Томилино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Троицк",
  },
  {
    region: "Москва и Московская обл.",
    city: "Туголесский Бор",
  },
  {
    region: "Москва и Московская обл.",
    city: "Тучково",
  },
  {
    region: "Москва и Московская обл.",
    city: "Уваровка",
  },
  {
    region: "Москва и Московская обл.",
    city: "Удельная",
  },
  {
    region: "Москва и Московская обл.",
    city: "Успенское",
  },
  {
    region: "Москва и Московская обл.",
    city: "Фирсановка",
  },
  {
    region: "Москва и Московская обл.",
    city: "Фосфоритный",
  },
  {
    region: "Москва и Московская обл.",
    city: "Фрязино",
  },
  {
    region: "Москва и Московская обл.",
    city: "Фряново",
  },
  {
    region: "Москва и Московская обл.",
    city: "Химки",
  },
  {
    region: "Москва и Московская обл.",
    city: "Хорлово",
  },
  {
    region: "Москва и Московская обл.",
    city: "Хотьково",
  },
  {
    region: "Москва и Московская обл.",
    city: "Черкизово",
  },
  {
    region: "Москва и Московская обл.",
    city: "Черноголовка",
  },
  {
    region: "Москва и Московская обл.",
    city: "Черусти",
  },
  {
    region: "Москва и Московская обл.",
    city: "Чехов",
  },
  {
    region: "Москва и Московская обл.",
    city: "Шарапово",
  },
  {
    region: "Москва и Московская обл.",
    city: "Шатура",
  },
  {
    region: "Москва и Московская обл.",
    city: "Шатурторф",
  },
  {
    region: "Москва и Московская обл.",
    city: "Шаховская",
  },
  {
    region: "Москва и Московская обл.",
    city: "Шереметьевский",
  },
  {
    region: "Москва и Московская обл.",
    city: "Щелково",
  },
  {
    region: "Москва и Московская обл.",
    city: "Щербинка",
  },
  {
    region: "Москва и Московская обл.",
    city: "Электрогорск",
  },
  {
    region: "Москва и Московская обл.",
    city: "Электросталь",
  },
  {
    region: "Москва и Московская обл.",
    city: "Электроугли",
  },
  {
    region: "Москва и Московская обл.",
    city: "Яхрома",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Санкт-Петербург",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Александровская",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Бокситогорск",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Большая Ижора",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Будогощь",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Вознесенье",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Волосово",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Волхов",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Всеволожск",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Выборг",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Вырица",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Высоцк",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Гатчина",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Дружная Горка",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Дубровка",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Ефимовский",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Зеленогорск",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Ивангород",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Каменногорск",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Кикерино",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Кингисепп",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Кириши",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Кировск",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Кобринское",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Колпино",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Коммунар",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Кронштадт",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Лисий Нос",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Лодейное Поле",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Ломоносов",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Луга",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Павловск",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Парголово",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Петродворец",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Пикалёво",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Подпорожье",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Приозерск",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Пушкин",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Сестрорецк",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Сланцы",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Сосновый Бор",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Тихвин",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Тосно",
  },
  {
    region: "Санкт-Петербург и область",
    city: "Шлиссельбург",
  },
  {
    region: "Адыгея",
    city: "Адыгейск",
  },
  {
    region: "Адыгея",
    city: "Майкоп",
  },
  {
    region: "Алтайский край",
    city: "Акташ",
  },
  {
    region: "Алтайский край",
    city: "Акутиха",
  },
  {
    region: "Алтайский край",
    city: "Алейск",
  },
  {
    region: "Алтайский край",
    city: "Алтайский",
  },
  {
    region: "Алтайский край",
    city: "Баево",
  },
  {
    region: "Алтайский край",
    city: "Барнаул",
  },
  {
    region: "Алтайский край",
    city: "Белово",
  },
  {
    region: "Алтайский край",
    city: "Белокуриха",
  },
  {
    region: "Алтайский край",
    city: "Белоярск",
  },
  {
    region: "Алтайский край",
    city: "Бийск",
  },
  {
    region: "Алтайский край",
    city: "Благовещенск",
  },
  {
    region: "Алтайский край",
    city: "Боровлянка",
  },
  {
    region: "Алтайский край",
    city: "Бурла",
  },
  {
    region: "Алтайский край",
    city: "Бурсоль",
  },
  {
    region: "Алтайский край",
    city: "Волчиха",
  },
  {
    region: "Алтайский край",
    city: "Горно-Алтайск",
  },
  {
    region: "Алтайский край",
    city: "Горняк",
  },
  {
    region: "Алтайский край",
    city: "Ельцовка",
  },
  {
    region: "Алтайский край",
    city: "Залесово",
  },
  {
    region: "Алтайский край",
    city: "Заринск",
  },
  {
    region: "Алтайский край",
    city: "Заток",
  },
  {
    region: "Алтайский край",
    city: "Змеиногорск",
  },
  {
    region: "Алтайский край",
    city: "Камень-на-Оби",
  },
  {
    region: "Алтайский край",
    city: "Ключи",
  },
  {
    region: "Алтайский край",
    city: "Кош-Агач",
  },
  {
    region: "Алтайский край",
    city: "Красногорское",
  },
  {
    region: "Алтайский край",
    city: "Краснощеково",
  },
  {
    region: "Алтайский край",
    city: "Кулунда",
  },
  {
    region: "Алтайский край",
    city: "Кытманово",
  },
  {
    region: "Алтайский край",
    city: "Мамонтово",
  },
  {
    region: "Алтайский край",
    city: "Новичиха",
  },
  {
    region: "Алтайский край",
    city: "Новоалтайск",
  },
  {
    region: "Алтайский край",
    city: "Онгудай",
  },
  {
    region: "Алтайский край",
    city: "Павловск",
  },
  {
    region: "Алтайский край",
    city: "Петропавловское",
  },
  {
    region: "Алтайский край",
    city: "Поспелиха",
  },
  {
    region: "Алтайский край",
    city: "Ребриха",
  },
  {
    region: "Алтайский край",
    city: "Родино",
  },
  {
    region: "Алтайский край",
    city: "Рубцовск",
  },
  {
    region: "Алтайский край",
    city: "Славгород",
  },
  {
    region: "Алтайский край",
    city: "Смоленское",
  },
  {
    region: "Алтайский край",
    city: "Солонешное",
  },
  {
    region: "Алтайский край",
    city: "Солтон",
  },
  {
    region: "Алтайский край",
    city: "Староаллейское",
  },
  {
    region: "Алтайский край",
    city: "Табуны",
  },
  {
    region: "Алтайский край",
    city: "Тальменка",
  },
  {
    region: "Алтайский край",
    city: "Топчиха",
  },
  {
    region: "Алтайский край",
    city: "Троицкое",
  },
  {
    region: "Алтайский край",
    city: "Турочак",
  },
  {
    region: "Алтайский край",
    city: "Тюменцево",
  },
  {
    region: "Алтайский край",
    city: "Угловское",
  },
  {
    region: "Алтайский край",
    city: "Усть-Калманка",
  },
  {
    region: "Алтайский край",
    city: "Усть-Кан",
  },
  {
    region: "Алтайский край",
    city: "Усть-Кокса",
  },
  {
    region: "Алтайский край",
    city: "Усть-Улаган",
  },
  {
    region: "Алтайский край",
    city: "Усть-Чарышская Пристань",
  },
  {
    region: "Алтайский край",
    city: "Хабары",
  },
  {
    region: "Алтайский край",
    city: "Целинное",
  },
  {
    region: "Алтайский край",
    city: "Чарышское",
  },
  {
    region: "Алтайский край",
    city: "Шебалино",
  },
  {
    region: "Алтайский край",
    city: "Шелаболиха",
  },
  {
    region: "Алтайский край",
    city: "Шипуново",
  },
  {
    region: "Амурская обл.",
    city: "Айгунь",
  },
  {
    region: "Амурская обл.",
    city: "Архара",
  },
  {
    region: "Амурская обл.",
    city: "Белогорск",
  },
  {
    region: "Амурская обл.",
    city: "Благовещенск (Амурская обл.)",
  },
  {
    region: "Амурская обл.",
    city: "Бурея",
  },
  {
    region: "Амурская обл.",
    city: "Возжаевка",
  },
  {
    region: "Амурская обл.",
    city: "Екатеринославка",
  },
  {
    region: "Амурская обл.",
    city: "Ерофей Павлович",
  },
  {
    region: "Амурская обл.",
    city: "Завитинск",
  },
  {
    region: "Амурская обл.",
    city: "Зея",
  },
  {
    region: "Амурская обл.",
    city: "Златоустовск",
  },
  {
    region: "Амурская обл.",
    city: "Ивановка",
  },
  {
    region: "Амурская обл.",
    city: "Коболдо",
  },
  {
    region: "Амурская обл.",
    city: "Магдагачи",
  },
  {
    region: "Амурская обл.",
    city: "Новобурейский",
  },
  {
    region: "Амурская обл.",
    city: "Поярково",
  },
  {
    region: "Амурская обл.",
    city: "Райчихинск",
  },
  {
    region: "Амурская обл.",
    city: "Ромны",
  },
  {
    region: "Амурская обл.",
    city: "Свободный",
  },
  {
    region: "Амурская обл.",
    city: "Серышево",
  },
  {
    region: "Амурская обл.",
    city: "Сковородино",
  },
  {
    region: "Амурская обл.",
    city: "Стойба",
  },
  {
    region: "Амурская обл.",
    city: "Тамбовка",
  },
  {
    region: "Амурская обл.",
    city: "Тында",
  },
  {
    region: "Амурская обл.",
    city: "Шимановск",
  },
  {
    region: "Амурская обл.",
    city: "Экимчан",
  },
  {
    region: "Амурская обл.",
    city: "Ядрино",
  },
  {
    region: "Архангельская обл.",
    city: "Амдерма",
  },
  {
    region: "Архангельская обл.",
    city: "Архангельск",
  },
  {
    region: "Архангельская обл.",
    city: "Березник",
  },
  {
    region: "Архангельская обл.",
    city: "Вельск",
  },
  {
    region: "Архангельская обл.",
    city: "Верхняя Тойма",
  },
  {
    region: "Архангельская обл.",
    city: "Волошка",
  },
  {
    region: "Архангельская обл.",
    city: "Вычегодский",
  },
  {
    region: "Архангельская обл.",
    city: "Емца",
  },
  {
    region: "Архангельская обл.",
    city: "Илеза",
  },
  {
    region: "Архангельская обл.",
    city: "Ильинско-Подомское",
  },
  {
    region: "Архангельская обл.",
    city: "Каргополь",
  },
  {
    region: "Архангельская обл.",
    city: "Карпогоры",
  },
  {
    region: "Архангельская обл.",
    city: "Кодино",
  },
  {
    region: "Архангельская обл.",
    city: "Коноша",
  },
  {
    region: "Архангельская обл.",
    city: "Коряжма",
  },
  {
    region: "Архангельская обл.",
    city: "Котлас",
  },
  {
    region: "Архангельская обл.",
    city: "Красноборск",
  },
  {
    region: "Архангельская обл.",
    city: "Лешуконское",
  },
  {
    region: "Архангельская обл.",
    city: "Мезень",
  },
  {
    region: "Архангельская обл.",
    city: "Мирный",
  },
  {
    region: "Архангельская обл.",
    city: "Нарьян-Мар",
  },
  {
    region: "Архангельская обл.",
    city: "Новодвинск",
  },
  {
    region: "Архангельская обл.",
    city: "Няндома",
  },
  {
    region: "Архангельская обл.",
    city: "Онега",
  },
  {
    region: "Архангельская обл.",
    city: "Пинега",
  },
  {
    region: "Архангельская обл.",
    city: "Плесецк",
  },
  {
    region: "Архангельская обл.",
    city: "Северодвинск",
  },
  {
    region: "Архангельская обл.",
    city: "Сольвычегодск",
  },
  {
    region: "Архангельская обл.",
    city: "Холмогоры",
  },
  {
    region: "Архангельская обл.",
    city: "Шенкурск",
  },
  {
    region: "Архангельская обл.",
    city: "Яренск",
  },
  {
    region: "Астраханская обл.",
    city: "Астрахань",
  },
  {
    region: "Астраханская обл.",
    city: "Ахтубинск",
  },
  {
    region: "Астраханская обл.",
    city: "Верхний Баскунчак",
  },
  {
    region: "Астраханская обл.",
    city: "Володарский",
  },
  {
    region: "Астраханская обл.",
    city: "Енотаевка",
  },
  {
    region: "Астраханская обл.",
    city: "Икряное",
  },
  {
    region: "Астраханская обл.",
    city: "Камызяк",
  },
  {
    region: "Астраханская обл.",
    city: "Капустин Яр",
  },
  {
    region: "Астраханская обл.",
    city: "Красный Яр",
  },
  {
    region: "Астраханская обл.",
    city: "Лиман",
  },
  {
    region: "Астраханская обл.",
    city: "Началово",
  },
  {
    region: "Астраханская обл.",
    city: "Харабали",
  },
  {
    region: "Астраханская обл.",
    city: "Черный Яр",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Аксаково",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Амзя",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Аскино",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Баймак",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Бакалы",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Белебей",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Белорецк",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Бижбуляк",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Бирск",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Благовещенск",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Большеустьикинское",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Бураево",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Верхнеяркеево",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Верхние Киги",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Верхние Татышлы",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Верхний Авзян",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Давлеканово",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Дуван",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Дюртюли",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Ермекеево",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Ермолаево",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Зилаир",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Зирган",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Иглино",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Инзер",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Исянгулово",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Ишимбай",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Кананикольское",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Кандры",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Караидель",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Караидельский",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Киргиз-Мияки",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Красноусольский",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Кумертау",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Кушнаренково",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Малояз",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Мелеуз",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Месягутово",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Мраково",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Нефтекамск",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Октябрьский",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Раевский",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Салават",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Сибай",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Старобалтачево",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Старосубхангулово",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Стерлибашево",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Стерлитамак",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Туймазы",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Уфа",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Учалы",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Федоровка",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Чекмагуш",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Чишмы",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Шаран",
  },
  {
    region: "Башкортостан(Башкирия)",
    city: "Янаул",
  },
  {
    region: "Белгородская обл.",
    city: "Алексеевка",
  },
  {
    region: "Белгородская обл.",
    city: "Белгород",
  },
  {
    region: "Белгородская обл.",
    city: "Борисовка",
  },
  {
    region: "Белгородская обл.",
    city: "Валуйки",
  },
  {
    region: "Белгородская обл.",
    city: "Вейделевка",
  },
  {
    region: "Белгородская обл.",
    city: "Волоконовка",
  },
  {
    region: "Белгородская обл.",
    city: "Грайворон",
  },
  {
    region: "Белгородская обл.",
    city: "Губкин",
  },
  {
    region: "Белгородская обл.",
    city: "Ивня",
  },
  {
    region: "Белгородская обл.",
    city: "Короча",
  },
  {
    region: "Белгородская обл.",
    city: "Красногвардейское",
  },
  {
    region: "Белгородская обл.",
    city: "Новый Оскол",
  },
  {
    region: "Белгородская обл.",
    city: "Ракитное",
  },
  {
    region: "Белгородская обл.",
    city: "Ровеньки",
  },
  {
    region: "Белгородская обл.",
    city: "Старый Оскол",
  },
  {
    region: "Белгородская обл.",
    city: "Строитель",
  },
  {
    region: "Белгородская обл.",
    city: "Чернянка",
  },
  {
    region: "Белгородская обл.",
    city: "Шебекино",
  },
  {
    region: "Брянская обл.",
    city: "Алтухово",
  },
  {
    region: "Брянская обл.",
    city: "Белая Березка",
  },
  {
    region: "Брянская обл.",
    city: "Белые Берега",
  },
  {
    region: "Брянская обл.",
    city: "Большое Полпино",
  },
  {
    region: "Брянская обл.",
    city: "Брянск",
  },
  {
    region: "Брянская обл.",
    city: "Бытошь",
  },
  {
    region: "Брянская обл.",
    city: "Выгоничи",
  },
  {
    region: "Брянская обл.",
    city: "Вышков",
  },
  {
    region: "Брянская обл.",
    city: "Гордеевка",
  },
  {
    region: "Брянская обл.",
    city: "Дубровка",
  },
  {
    region: "Брянская обл.",
    city: "Дятьково",
  },
  {
    region: "Брянская обл.",
    city: "Жирятино",
  },
  {
    region: "Брянская обл.",
    city: "Жуковка",
  },
  {
    region: "Брянская обл.",
    city: "Злынка",
  },
  {
    region: "Брянская обл.",
    city: "Ивот",
  },
  {
    region: "Брянская обл.",
    city: "Карачев",
  },
  {
    region: "Брянская обл.",
    city: "Клетня",
  },
  {
    region: "Брянская обл.",
    city: "Климово",
  },
  {
    region: "Брянская обл.",
    city: "Клинцы",
  },
  {
    region: "Брянская обл.",
    city: "Кокаревка",
  },
  {
    region: "Брянская обл.",
    city: "Комаричи",
  },
  {
    region: "Брянская обл.",
    city: "Красная Гора",
  },
  {
    region: "Брянская обл.",
    city: "Локоть",
  },
  {
    region: "Брянская обл.",
    city: "Мглин",
  },
  {
    region: "Брянская обл.",
    city: "Навля",
  },
  {
    region: "Брянская обл.",
    city: "Новозыбков",
  },
  {
    region: "Брянская обл.",
    city: "Погар",
  },
  {
    region: "Брянская обл.",
    city: "Почеп",
  },
  {
    region: "Брянская обл.",
    city: "Ржаница",
  },
  {
    region: "Брянская обл.",
    city: "Рогнедино",
  },
  {
    region: "Брянская обл.",
    city: "Севск",
  },
  {
    region: "Брянская обл.",
    city: "Стародуб",
  },
  {
    region: "Брянская обл.",
    city: "Суземка",
  },
  {
    region: "Брянская обл.",
    city: "Сураж",
  },
  {
    region: "Брянская обл.",
    city: "Трубчевск",
  },
  {
    region: "Брянская обл.",
    city: "Унеча",
  },
  {
    region: "Бурятия",
    city: "Бабушкин",
  },
  {
    region: "Бурятия",
    city: "Багдарин",
  },
  {
    region: "Бурятия",
    city: "Баргузин",
  },
  {
    region: "Бурятия",
    city: "Баянгол",
  },
  {
    region: "Бурятия",
    city: "Бичура",
  },
  {
    region: "Бурятия",
    city: "Выдрино",
  },
  {
    region: "Бурятия",
    city: "Гусиное Озеро",
  },
  {
    region: "Бурятия",
    city: "Гусиноозерск",
  },
  {
    region: "Бурятия",
    city: "Заиграево",
  },
  {
    region: "Бурятия",
    city: "Закаменск",
  },
  {
    region: "Бурятия",
    city: "Иволгинск",
  },
  {
    region: "Бурятия",
    city: "Илька",
  },
  {
    region: "Бурятия",
    city: "Кабанск",
  },
  {
    region: "Бурятия",
    city: "Каменск",
  },
  {
    region: "Бурятия",
    city: "Кижинга",
  },
  {
    region: "Бурятия",
    city: "Курумкан",
  },
  {
    region: "Бурятия",
    city: "Кырен",
  },
  {
    region: "Бурятия",
    city: "Кяхта",
  },
  {
    region: "Бурятия",
    city: "Монды",
  },
  {
    region: "Бурятия",
    city: "Мухоршибирь",
  },
  {
    region: "Бурятия",
    city: "Нижнеангарск",
  },
  {
    region: "Бурятия",
    city: "Орлик",
  },
  {
    region: "Бурятия",
    city: "Петропавловка",
  },
  {
    region: "Бурятия",
    city: "Романовка",
  },
  {
    region: "Бурятия",
    city: "Северобайкальск",
  },
  {
    region: "Бурятия",
    city: "Селенгинск",
  },
  {
    region: "Бурятия",
    city: "Сосново-Озерское",
  },
  {
    region: "Бурятия",
    city: "Таксимо",
  },
  {
    region: "Бурятия",
    city: "Турунтаево",
  },
  {
    region: "Бурятия",
    city: "Улан-Удэ",
  },
  {
    region: "Бурятия",
    city: "Хоринск",
  },
  {
    region: "Владимирская обл.",
    city: "Александров",
  },
  {
    region: "Владимирская обл.",
    city: "Андреево",
  },
  {
    region: "Владимирская обл.",
    city: "Анопино",
  },
  {
    region: "Владимирская обл.",
    city: "Бавлены",
  },
  {
    region: "Владимирская обл.",
    city: "Балакирево",
  },
  {
    region: "Владимирская обл.",
    city: "Боголюбово",
  },
  {
    region: "Владимирская обл.",
    city: "Великодворский",
  },
  {
    region: "Владимирская обл.",
    city: "Вербовский",
  },
  {
    region: "Владимирская обл.",
    city: "Владимир",
  },
  {
    region: "Владимирская обл.",
    city: "Вязники",
  },
  {
    region: "Владимирская обл.",
    city: "Городищи",
  },
  {
    region: "Владимирская обл.",
    city: "Гороховец",
  },
  {
    region: "Владимирская обл.",
    city: "Гусевский",
  },
  {
    region: "Владимирская обл.",
    city: "Гусь Хрустальный",
  },
  {
    region: "Владимирская обл.",
    city: "Золотково",
  },
  {
    region: "Владимирская обл.",
    city: "Иванищи",
  },
  {
    region: "Владимирская обл.",
    city: "Камешково",
  },
  {
    region: "Владимирская обл.",
    city: "Карабаново",
  },
  {
    region: "Владимирская обл.",
    city: "Киржач",
  },
  {
    region: "Владимирская обл.",
    city: "Ковров",
  },
  {
    region: "Владимирская обл.",
    city: "Кольчугино",
  },
  {
    region: "Владимирская обл.",
    city: "Красная Горбатка",
  },
  {
    region: "Владимирская обл.",
    city: "Меленки",
  },
  {
    region: "Владимирская обл.",
    city: "Муром",
  },
  {
    region: "Владимирская обл.",
    city: "Петушки",
  },
  {
    region: "Владимирская обл.",
    city: "Покров",
  },
  {
    region: "Владимирская обл.",
    city: "Собинка",
  },
  {
    region: "Владимирская обл.",
    city: "Судогда",
  },
  {
    region: "Владимирская обл.",
    city: "Суздаль",
  },
  {
    region: "Владимирская обл.",
    city: "Юрьев-Польский",
  },
  {
    region: "Волгоградская обл.",
    city: "Алексеевская",
  },
  {
    region: "Волгоградская обл.",
    city: "Алущевск",
  },
  {
    region: "Волгоградская обл.",
    city: "Быково",
  },
  {
    region: "Волгоградская обл.",
    city: "Волгоград",
  },
  {
    region: "Волгоградская обл.",
    city: "Волжский",
  },
  {
    region: "Волгоградская обл.",
    city: "Городище",
  },
  {
    region: "Волгоградская обл.",
    city: "Дубовка",
  },
  {
    region: "Волгоградская обл.",
    city: "Елань",
  },
  {
    region: "Волгоградская обл.",
    city: "Жирновск",
  },
  {
    region: "Волгоградская обл.",
    city: "Иловля",
  },
  {
    region: "Волгоградская обл.",
    city: "Калач-на-Дону",
  },
  {
    region: "Волгоградская обл.",
    city: "Камышин",
  },
  {
    region: "Волгоградская обл.",
    city: "Кириллов",
  },
  {
    region: "Волгоградская обл.",
    city: "Клетский",
  },
  {
    region: "Волгоградская обл.",
    city: "Котельниково",
  },
  {
    region: "Волгоградская обл.",
    city: "Котово",
  },
  {
    region: "Волгоградская обл.",
    city: "Кумылженская",
  },
  {
    region: "Волгоградская обл.",
    city: "Ленинск",
  },
  {
    region: "Волгоградская обл.",
    city: "Михайловка",
  },
  {
    region: "Волгоградская обл.",
    city: "Нехаевский",
  },
  {
    region: "Волгоградская обл.",
    city: "Николаевск",
  },
  {
    region: "Волгоградская обл.",
    city: "Новоаннинский",
  },
  {
    region: "Волгоградская обл.",
    city: "Новониколаевский",
  },
  {
    region: "Волгоградская обл.",
    city: "Ольховка",
  },
  {
    region: "Волгоградская обл.",
    city: "Палласовка",
  },
  {
    region: "Волгоградская обл.",
    city: "Рудня",
  },
  {
    region: "Волгоградская обл.",
    city: "Светлый Яр",
  },
  {
    region: "Волгоградская обл.",
    city: "Серафимович",
  },
  {
    region: "Волгоградская обл.",
    city: "Средняя Ахтуба",
  },
  {
    region: "Волгоградская обл.",
    city: "Сталинград",
  },
  {
    region: "Волгоградская обл.",
    city: "Старая Полтавка",
  },
  {
    region: "Волгоградская обл.",
    city: "Суровикино",
  },
  {
    region: "Волгоградская обл.",
    city: "Урюпинск",
  },
  {
    region: "Волгоградская обл.",
    city: "Фролово",
  },
  {
    region: "Волгоградская обл.",
    city: "Чернышковский",
  },
  {
    region: "Вологодская обл.",
    city: "Бабаево",
  },
  {
    region: "Вологодская обл.",
    city: "Белозерск",
  },
  {
    region: "Вологодская обл.",
    city: "Великий Устюг",
  },
  {
    region: "Вологодская обл.",
    city: "Верховажье",
  },
  {
    region: "Вологодская обл.",
    city: "Вожега",
  },
  {
    region: "Вологодская обл.",
    city: "Вологда",
  },
  {
    region: "Вологодская обл.",
    city: "Вохтога",
  },
  {
    region: "Вологодская обл.",
    city: "Вытегра",
  },
  {
    region: "Вологодская обл.",
    city: "Грязовец",
  },
  {
    region: "Вологодская обл.",
    city: "Кадников",
  },
  {
    region: "Вологодская обл.",
    city: "Кадуй",
  },
  {
    region: "Вологодская обл.",
    city: "Кичменгский Городок",
  },
  {
    region: "Вологодская обл.",
    city: "Липин Бор",
  },
  {
    region: "Вологодская обл.",
    city: "Никольск",
  },
  {
    region: "Вологодская обл.",
    city: "Нюксеница",
  },
  {
    region: "Вологодская обл.",
    city: "Сокол",
  },
  {
    region: "Вологодская обл.",
    city: "Сямжа",
  },
  {
    region: "Вологодская обл.",
    city: "Тарногский Городок",
  },
  {
    region: "Вологодская обл.",
    city: "Тотьма",
  },
  {
    region: "Вологодская обл.",
    city: "Устюжна",
  },
  {
    region: "Вологодская обл.",
    city: "Харовск",
  },
  {
    region: "Вологодская обл.",
    city: "Чагода",
  },
  {
    region: "Вологодская обл.",
    city: "Череповец",
  },
  {
    region: "Вологодская обл.",
    city: "Шексна",
  },
  {
    region: "Вологодская обл.",
    city: "Шуйское",
  },
  {
    region: "Воронежская обл.",
    city: "Анна",
  },
  {
    region: "Воронежская обл.",
    city: "Бобров",
  },
  {
    region: "Воронежская обл.",
    city: "Богучар",
  },
  {
    region: "Воронежская обл.",
    city: "Борисоглебск",
  },
  {
    region: "Воронежская обл.",
    city: "Бутурлиновка",
  },
  {
    region: "Воронежская обл.",
    city: "Верхний Мамон",
  },
  {
    region: "Воронежская обл.",
    city: "Верхняя Хава",
  },
  {
    region: "Воронежская обл.",
    city: "Воробьевка",
  },
  {
    region: "Воронежская обл.",
    city: "Воронеж",
  },
  {
    region: "Воронежская обл.",
    city: "Грибановский",
  },
  {
    region: "Воронежская обл.",
    city: "Давыдовка",
  },
  {
    region: "Воронежская обл.",
    city: "Елань-Коленовский",
  },
  {
    region: "Воронежская обл.",
    city: "Калач",
  },
  {
    region: "Воронежская обл.",
    city: "Кантемировка",
  },
  {
    region: "Воронежская обл.",
    city: "Лиски",
  },
  {
    region: "Воронежская обл.",
    city: "Нижнедевицк",
  },
  {
    region: "Воронежская обл.",
    city: "Новая Усмань",
  },
  {
    region: "Воронежская обл.",
    city: "Новохоперск",
  },
  {
    region: "Воронежская обл.",
    city: "Ольховатка",
  },
  {
    region: "Воронежская обл.",
    city: "Острогожск",
  },
  {
    region: "Воронежская обл.",
    city: "Павловск",
  },
  {
    region: "Воронежская обл.",
    city: "Панино",
  },
  {
    region: "Воронежская обл.",
    city: "Петропавловка",
  },
  {
    region: "Воронежская обл.",
    city: "Поворино",
  },
  {
    region: "Воронежская обл.",
    city: "Подгоренский",
  },
  {
    region: "Воронежская обл.",
    city: "Рамонь",
  },
  {
    region: "Воронежская обл.",
    city: "Репьевка",
  },
  {
    region: "Воронежская обл.",
    city: "Россошь",
  },
  {
    region: "Воронежская обл.",
    city: "Семилуки",
  },
  {
    region: "Воронежская обл.",
    city: "Таловая",
  },
  {
    region: "Воронежская обл.",
    city: "Терновка",
  },
  {
    region: "Воронежская обл.",
    city: "Хохольский",
  },
  {
    region: "Воронежская обл.",
    city: "Эртиль",
  },
  {
    region: "Воронежская обл.",
    city: "нововоронеж",
  },
  {
    region: "Дагестан",
    city: "Агвали",
  },
  {
    region: "Дагестан",
    city: "Акуша",
  },
  {
    region: "Дагестан",
    city: "Ахты",
  },
  {
    region: "Дагестан",
    city: "Ачису",
  },
  {
    region: "Дагестан",
    city: "Бабаюрт",
  },
  {
    region: "Дагестан",
    city: "Бежта",
  },
  {
    region: "Дагестан",
    city: "Ботлих",
  },
  {
    region: "Дагестан",
    city: "Буйнакск",
  },
  {
    region: "Дагестан",
    city: "Вачи",
  },
  {
    region: "Дагестан",
    city: "Гергебиль",
  },
  {
    region: "Дагестан",
    city: "Гуниб",
  },
  {
    region: "Дагестан",
    city: "Дагестанские Огни",
  },
  {
    region: "Дагестан",
    city: "Дербент",
  },
  {
    region: "Дагестан",
    city: "Дылым",
  },
  {
    region: "Дагестан",
    city: "Ершовка",
  },
  {
    region: "Дагестан",
    city: "Избербаш",
  },
  {
    region: "Дагестан",
    city: "Карабудахкент",
  },
  {
    region: "Дагестан",
    city: "Карата",
  },
  {
    region: "Дагестан",
    city: "Каспийск",
  },
  {
    region: "Дагестан",
    city: "Касумкент",
  },
  {
    region: "Дагестан",
    city: "Кизилюрт",
  },
  {
    region: "Дагестан",
    city: "Кизляр",
  },
  {
    region: "Дагестан",
    city: "Кочубей",
  },
  {
    region: "Дагестан",
    city: "Кумух",
  },
  {
    region: "Дагестан",
    city: "Курах",
  },
  {
    region: "Дагестан",
    city: "Магарамкент",
  },
  {
    region: "Дагестан",
    city: "Маджалис",
  },
  {
    region: "Дагестан",
    city: "Махачкала",
  },
  {
    region: "Дагестан",
    city: "Мехельта",
  },
  {
    region: "Дагестан",
    city: "Новолакское",
  },
  {
    region: "Дагестан",
    city: "Рутул",
  },
  {
    region: "Дагестан",
    city: "Советское",
  },
  {
    region: "Дагестан",
    city: "Тарумовка",
  },
  {
    region: "Дагестан",
    city: "Терекли-Мектеб",
  },
  {
    region: "Дагестан",
    city: "Тлярата",
  },
  {
    region: "Дагестан",
    city: "Тпиг",
  },
  {
    region: "Дагестан",
    city: "Уркарах",
  },
  {
    region: "Дагестан",
    city: "Хасавюрт",
  },
  {
    region: "Дагестан",
    city: "Хив",
  },
  {
    region: "Дагестан",
    city: "Хунзах",
  },
  {
    region: "Дагестан",
    city: "Цуриб",
  },
  {
    region: "Дагестан",
    city: "Южно-Сухокумск",
  },
  {
    region: "Еврейская обл.",
    city: "Биробиджан",
  },
  {
    region: "Ивановская обл.",
    city: "Архиповка",
  },
  {
    region: "Ивановская обл.",
    city: "Верхний Ландех",
  },
  {
    region: "Ивановская обл.",
    city: "Вичуга",
  },
  {
    region: "Ивановская обл.",
    city: "Гаврилов Посад",
  },
  {
    region: "Ивановская обл.",
    city: "Долматовский",
  },
  {
    region: "Ивановская обл.",
    city: "Дуляпино",
  },
  {
    region: "Ивановская обл.",
    city: "Заволжск",
  },
  {
    region: "Ивановская обл.",
    city: "Заречный",
  },
  {
    region: "Ивановская обл.",
    city: "Иваново",
  },
  {
    region: "Ивановская обл.",
    city: "Иваньковский",
  },
  {
    region: "Ивановская обл.",
    city: "Ильинское-Хованское",
  },
  {
    region: "Ивановская обл.",
    city: "Каминский",
  },
  {
    region: "Ивановская обл.",
    city: "Кинешма",
  },
  {
    region: "Ивановская обл.",
    city: "Комсомольск",
  },
  {
    region: "Ивановская обл.",
    city: "Кохма",
  },
  {
    region: "Ивановская обл.",
    city: "Лух",
  },
  {
    region: "Ивановская обл.",
    city: "Палех",
  },
  {
    region: "Ивановская обл.",
    city: "Пестяки",
  },
  {
    region: "Ивановская обл.",
    city: "Приволжск",
  },
  {
    region: "Ивановская обл.",
    city: "Пучеж",
  },
  {
    region: "Ивановская обл.",
    city: "Родники",
  },
  {
    region: "Ивановская обл.",
    city: "Савино",
  },
  {
    region: "Ивановская обл.",
    city: "Сокольское",
  },
  {
    region: "Ивановская обл.",
    city: "Тейково",
  },
  {
    region: "Ивановская обл.",
    city: "Фурманов",
  },
  {
    region: "Ивановская обл.",
    city: "Шуя",
  },
  {
    region: "Ивановская обл.",
    city: "Южа",
  },
  {
    region: "Ивановская обл.",
    city: "Юрьевец",
  },
  {
    region: "Иркутская обл.",
    city: "Алексеевск",
  },
  {
    region: "Иркутская обл.",
    city: "Алзамай",
  },
  {
    region: "Иркутская обл.",
    city: "Алыгжер",
  },
  {
    region: "Иркутская обл.",
    city: "Ангарск",
  },
  {
    region: "Иркутская обл.",
    city: "Артемовский",
  },
  {
    region: "Иркутская обл.",
    city: "Атагай",
  },
  {
    region: "Иркутская обл.",
    city: "Байкал",
  },
  {
    region: "Иркутская обл.",
    city: "Байкальск",
  },
  {
    region: "Иркутская обл.",
    city: "Балаганск",
  },
  {
    region: "Иркутская обл.",
    city: "Баяндай",
  },
  {
    region: "Иркутская обл.",
    city: "Бирюсинск",
  },
  {
    region: "Иркутская обл.",
    city: "Бодайбо",
  },
  {
    region: "Иркутская обл.",
    city: "Большая Речка",
  },
  {
    region: "Иркутская обл.",
    city: "Большой Луг",
  },
  {
    region: "Иркутская обл.",
    city: "Бохан",
  },
  {
    region: "Иркутская обл.",
    city: "Братск",
  },
  {
    region: "Иркутская обл.",
    city: "Видим",
  },
  {
    region: "Иркутская обл.",
    city: "Витимский",
  },
  {
    region: "Иркутская обл.",
    city: "Вихоревка",
  },
  {
    region: "Иркутская обл.",
    city: "Еланцы",
  },
  {
    region: "Иркутская обл.",
    city: "Ербогачен",
  },
  {
    region: "Иркутская обл.",
    city: "Железногорск-Илимский",
  },
  {
    region: "Иркутская обл.",
    city: "Жигалово",
  },
  {
    region: "Иркутская обл.",
    city: "Забитуй",
  },
  {
    region: "Иркутская обл.",
    city: "Залари",
  },
  {
    region: "Иркутская обл.",
    city: "Звездный",
  },
  {
    region: "Иркутская обл.",
    city: "Зима",
  },
  {
    region: "Иркутская обл.",
    city: "Иркутск",
  },
  {
    region: "Иркутская обл.",
    city: "Казачинское",
  },
  {
    region: "Иркутская обл.",
    city: "Качуг",
  },
  {
    region: "Иркутская обл.",
    city: "Квиток",
  },
  {
    region: "Иркутская обл.",
    city: "Киренск",
  },
  {
    region: "Иркутская обл.",
    city: "Куйтун",
  },
  {
    region: "Иркутская обл.",
    city: "Култук",
  },
  {
    region: "Иркутская обл.",
    city: "Кутулик",
  },
  {
    region: "Иркутская обл.",
    city: "Мама",
  },
  {
    region: "Иркутская обл.",
    city: "Нижнеудинск",
  },
  {
    region: "Иркутская обл.",
    city: "Оса",
  },
  {
    region: "Иркутская обл.",
    city: "Саянск",
  },
  {
    region: "Иркутская обл.",
    city: "Слюдянка",
  },
  {
    region: "Иркутская обл.",
    city: "Тайшет",
  },
  {
    region: "Иркутская обл.",
    city: "Тулун",
  },
  {
    region: "Иркутская обл.",
    city: "Усолье-Сибирское",
  },
  {
    region: "Иркутская обл.",
    city: "Усть-Илимск",
  },
  {
    region: "Иркутская обл.",
    city: "Усть-Кут",
  },
  {
    region: "Иркутская обл.",
    city: "Усть-Ордынский",
  },
  {
    region: "Иркутская обл.",
    city: "Усть-Уда",
  },
  {
    region: "Иркутская обл.",
    city: "Черемхово",
  },
  {
    region: "Иркутская обл.",
    city: "Чунский",
  },
  {
    region: "Иркутская обл.",
    city: "Шелехов",
  },
  {
    region: "Кабардино-Балкария",
    city: "Баксан",
  },
  {
    region: "Кабардино-Балкария",
    city: "Майский",
  },
  {
    region: "Кабардино-Балкария",
    city: "Нальчик",
  },
  {
    region: "Кабардино-Балкария",
    city: "Нарткала",
  },
  {
    region: "Кабардино-Балкария",
    city: "Прохладный",
  },
  {
    region: "Кабардино-Балкария",
    city: "Советское",
  },
  {
    region: "Кабардино-Балкария",
    city: "Терек",
  },
  {
    region: "Кабардино-Балкария",
    city: "Тырныауз",
  },
  {
    region: "Кабардино-Балкария",
    city: "Чегем-Первый",
  },
  {
    region: "Калининградская обл.",
    city: "Багратионовск",
  },
  {
    region: "Калининградская обл.",
    city: "Балтийск",
  },
  {
    region: "Калининградская обл.",
    city: "Гвардейск",
  },
  {
    region: "Калининградская обл.",
    city: "Гурьевск",
  },
  {
    region: "Калининградская обл.",
    city: "Гусев",
  },
  {
    region: "Калининградская обл.",
    city: "Железнодорожный",
  },
  {
    region: "Калининградская обл.",
    city: "Зеленоградск",
  },
  {
    region: "Калининградская обл.",
    city: "Знаменск",
  },
  {
    region: "Калининградская обл.",
    city: "Кёнигсберг",
  },
  {
    region: "Калининградская обл.",
    city: "Калининград",
  },
  {
    region: "Калининградская обл.",
    city: "Кенисберг",
  },
  {
    region: "Калининградская обл.",
    city: "Краснознаменск",
  },
  {
    region: "Калининградская обл.",
    city: "Мамоново",
  },
  {
    region: "Калининградская обл.",
    city: "Неман",
  },
  {
    region: "Калининградская обл.",
    city: "Нестеров",
  },
  {
    region: "Калининградская обл.",
    city: "Озерск",
  },
  {
    region: "Калининградская обл.",
    city: "Полесск",
  },
  {
    region: "Калининградская обл.",
    city: "Правдинск",
  },
  {
    region: "Калининградская обл.",
    city: "Светлогорск",
  },
  {
    region: "Калининградская обл.",
    city: "Светлый",
  },
  {
    region: "Калининградская обл.",
    city: "Славск",
  },
  {
    region: "Калининградская обл.",
    city: "Советск",
  },
  {
    region: "Калининградская обл.",
    city: "Черняховск",
  },
  {
    region: "Калмыкия",
    city: "Аршань",
  },
  {
    region: "Калмыкия",
    city: "Каспийский",
  },
  {
    region: "Калмыкия",
    city: "Комсомольский",
  },
  {
    region: "Калмыкия",
    city: "Малые Дербеты",
  },
  {
    region: "Калмыкия",
    city: "Приютное",
  },
  {
    region: "Калмыкия",
    city: "Советское",
  },
  {
    region: "Калмыкия",
    city: "Троицкое",
  },
  {
    region: "Калмыкия",
    city: "Утта",
  },
  {
    region: "Калмыкия",
    city: "Цаган-Аман",
  },
  {
    region: "Калмыкия",
    city: "Элиста",
  },
  {
    region: "Калмыкия",
    city: "Юста",
  },
  {
    region: "Калмыкия",
    city: "Яшалта",
  },
  {
    region: "Калмыкия",
    city: "Яшкуль",
  },
  {
    region: "Калужская обл.",
    city: "Бабынино",
  },
  {
    region: "Калужская обл.",
    city: "Балабаново",
  },
  {
    region: "Калужская обл.",
    city: "Барятино",
  },
  {
    region: "Калужская обл.",
    city: "Белоусово",
  },
  {
    region: "Калужская обл.",
    city: "Бетлица",
  },
  {
    region: "Калужская обл.",
    city: "Боровск",
  },
  {
    region: "Калужская обл.",
    city: "Дугна",
  },
  {
    region: "Калужская обл.",
    city: "Дудоровский",
  },
  {
    region: "Калужская обл.",
    city: "Думиничи",
  },
  {
    region: "Калужская обл.",
    city: "Еленский",
  },
  {
    region: "Калужская обл.",
    city: "Жиздра",
  },
  {
    region: "Калужская обл.",
    city: "Износки",
  },
  {
    region: "Калужская обл.",
    city: "Калуга",
  },
  {
    region: "Калужская обл.",
    city: "Киров",
  },
  {
    region: "Калужская обл.",
    city: "Козельск",
  },
  {
    region: "Калужская обл.",
    city: "Кондрово",
  },
  {
    region: "Калужская обл.",
    city: "Людиново",
  },
  {
    region: "Калужская обл.",
    city: "Малоярославец",
  },
  {
    region: "Калужская обл.",
    city: "Медынь",
  },
  {
    region: "Калужская обл.",
    city: "Мещовск",
  },
  {
    region: "Калужская обл.",
    city: "Мосальск",
  },
  {
    region: "Калужская обл.",
    city: "Обнинск",
  },
  {
    region: "Калужская обл.",
    city: "Перемышль",
  },
  {
    region: "Калужская обл.",
    city: "Спас-Деменск",
  },
  {
    region: "Калужская обл.",
    city: "Сухиничи",
  },
  {
    region: "Калужская обл.",
    city: "Таруса",
  },
  {
    region: "Калужская обл.",
    city: "Ульяново",
  },
  {
    region: "Калужская обл.",
    city: "Ферзиково",
  },
  {
    region: "Калужская обл.",
    city: "Хвастовичи",
  },
  {
    region: "Калужская обл.",
    city: "Юхнов",
  },
  {
    region: "Камчатская обл.",
    city: "Атласово",
  },
  {
    region: "Камчатская обл.",
    city: "Аянка",
  },
  {
    region: "Камчатская обл.",
    city: "Большерецк",
  },
  {
    region: "Камчатская обл.",
    city: "Вилючинск",
  },
  {
    region: "Камчатская обл.",
    city: "Елизово",
  },
  {
    region: "Камчатская обл.",
    city: "Ильпырский",
  },
  {
    region: "Камчатская обл.",
    city: "Каменское",
  },
  {
    region: "Камчатская обл.",
    city: "Кировский",
  },
  {
    region: "Камчатская обл.",
    city: "Ключи",
  },
  {
    region: "Камчатская обл.",
    city: "Крапивная",
  },
  {
    region: "Камчатская обл.",
    city: "Мильково",
  },
  {
    region: "Камчатская обл.",
    city: "Никольское",
  },
  {
    region: "Камчатская обл.",
    city: "Озерновский",
  },
  {
    region: "Камчатская обл.",
    city: "Оссора",
  },
  {
    region: "Камчатская обл.",
    city: "Палана",
  },
  {
    region: "Камчатская обл.",
    city: "Парень",
  },
  {
    region: "Камчатская обл.",
    city: "Пахачи",
  },
  {
    region: "Камчатская обл.",
    city: "Петропавловск-Камчатский",
  },
  {
    region: "Камчатская обл.",
    city: "Тигиль",
  },
  {
    region: "Камчатская обл.",
    city: "Тиличики",
  },
  {
    region: "Камчатская обл.",
    city: "Усть-Большерецк",
  },
  {
    region: "Камчатская обл.",
    city: "Усть-Камчатск",
  },
  {
    region: "Карелия",
    city: "Амбарный",
  },
  {
    region: "Карелия",
    city: "Беломорск",
  },
  {
    region: "Карелия",
    city: "Валаам",
  },
  {
    region: "Карелия",
    city: "Вирандозеро",
  },
  {
    region: "Карелия",
    city: "Гирвас",
  },
  {
    region: "Карелия",
    city: "Деревянка",
  },
  {
    region: "Карелия",
    city: "Идель",
  },
  {
    region: "Карелия",
    city: "Ильинский",
  },
  {
    region: "Карелия",
    city: "Импалахти",
  },
  {
    region: "Карелия",
    city: "Калевала",
  },
  {
    region: "Карелия",
    city: "Кемь",
  },
  {
    region: "Карелия",
    city: "Кестеньга",
  },
  {
    region: "Карелия",
    city: "Кондопога",
  },
  {
    region: "Карелия",
    city: "Костомукша",
  },
  {
    region: "Карелия",
    city: "Лахденпохья",
  },
  {
    region: "Карелия",
    city: "Лоухи",
  },
  {
    region: "Карелия",
    city: "Медвежьегорск",
  },
  {
    region: "Карелия",
    city: "Муезерский",
  },
  {
    region: "Карелия",
    city: "Олонец",
  },
  {
    region: "Карелия",
    city: "Петрозаводск",
  },
  {
    region: "Карелия",
    city: "Питкяранта",
  },
  {
    region: "Карелия",
    city: "Повенец",
  },
  {
    region: "Карелия",
    city: "Пряжа",
  },
  {
    region: "Карелия",
    city: "Пудож",
  },
  {
    region: "Карелия",
    city: "Сегежа",
  },
  {
    region: "Карелия",
    city: "Сортавала",
  },
  {
    region: "Карелия",
    city: "Софпорог",
  },
  {
    region: "Карелия",
    city: "Суоярви",
  },
  {
    region: "Кемеровская обл.",
    city: "Анжеро-Судженск",
  },
  {
    region: "Кемеровская обл.",
    city: "Барзас",
  },
  {
    region: "Кемеровская обл.",
    city: "Белово",
  },
  {
    region: "Кемеровская обл.",
    city: "Белогорск",
  },
  {
    region: "Кемеровская обл.",
    city: "Березовский",
  },
  {
    region: "Кемеровская обл.",
    city: "Грамотеино",
  },
  {
    region: "Кемеровская обл.",
    city: "Гурьевск",
  },
  {
    region: "Кемеровская обл.",
    city: "Ижморский",
  },
  {
    region: "Кемеровская обл.",
    city: "Итатский",
  },
  {
    region: "Кемеровская обл.",
    city: "Калтан",
  },
  {
    region: "Кемеровская обл.",
    city: "Кедровка",
  },
  {
    region: "Кемеровская обл.",
    city: "Кемерово",
  },
  {
    region: "Кемеровская обл.",
    city: "Киселевск",
  },
  {
    region: "Кемеровская обл.",
    city: "Крапивинский",
  },
  {
    region: "Кемеровская обл.",
    city: "Ленинск-Кузнецкий",
  },
  {
    region: "Кемеровская обл.",
    city: "Мариинск",
  },
  {
    region: "Кемеровская обл.",
    city: "Междуреченск",
  },
  {
    region: "Кемеровская обл.",
    city: "Мыски",
  },
  {
    region: "Кемеровская обл.",
    city: "Новокузнецк",
  },
  {
    region: "Кемеровская обл.",
    city: "Осинники",
  },
  {
    region: "Кемеровская обл.",
    city: "Прокопьевск",
  },
  {
    region: "Кемеровская обл.",
    city: "Промышленная",
  },
  {
    region: "Кемеровская обл.",
    city: "Тайга",
  },
  {
    region: "Кемеровская обл.",
    city: "Таштагол",
  },
  {
    region: "Кемеровская обл.",
    city: "Тисуль",
  },
  {
    region: "Кемеровская обл.",
    city: "Топки",
  },
  {
    region: "Кемеровская обл.",
    city: "Тяжинский",
  },
  {
    region: "Кемеровская обл.",
    city: "Юрга",
  },
  {
    region: "Кемеровская обл.",
    city: "Яшкино",
  },
  {
    region: "Кемеровская обл.",
    city: "Яя",
  },
  {
    region: "Кировская обл.",
    city: "Арбаж",
  },
  {
    region: "Кировская обл.",
    city: "Аркуль",
  },
  {
    region: "Кировская обл.",
    city: "Белая Холуница",
  },
  {
    region: "Кировская обл.",
    city: "Богородское",
  },
  {
    region: "Кировская обл.",
    city: "Боровой",
  },
  {
    region: "Кировская обл.",
    city: "Верхошижемье",
  },
  {
    region: "Кировская обл.",
    city: "Вятские Поляны",
  },
  {
    region: "Кировская обл.",
    city: "Зуевка",
  },
  {
    region: "Кировская обл.",
    city: "Каринторф",
  },
  {
    region: "Кировская обл.",
    city: "Кикнур",
  },
  {
    region: "Кировская обл.",
    city: "Кильмезь",
  },
  {
    region: "Кировская обл.",
    city: "Киров",
  },
  {
    region: "Кировская обл.",
    city: "Кирово-Чепецк",
  },
  {
    region: "Кировская обл.",
    city: "Кирс",
  },
  {
    region: "Кировская обл.",
    city: "Кобра",
  },
  {
    region: "Кировская обл.",
    city: "Котельнич",
  },
  {
    region: "Кировская обл.",
    city: "Кумены",
  },
  {
    region: "Кировская обл.",
    city: "Ленинское",
  },
  {
    region: "Кировская обл.",
    city: "Луза",
  },
  {
    region: "Кировская обл.",
    city: "Малмыж",
  },
  {
    region: "Кировская обл.",
    city: "Мураши",
  },
  {
    region: "Кировская обл.",
    city: "Нагорск",
  },
  {
    region: "Кировская обл.",
    city: "Нема",
  },
  {
    region: "Кировская обл.",
    city: "Нововятск",
  },
  {
    region: "Кировская обл.",
    city: "Нолинск",
  },
  {
    region: "Кировская обл.",
    city: "Омутнинск",
  },
  {
    region: "Кировская обл.",
    city: "Опарино",
  },
  {
    region: "Кировская обл.",
    city: "Оричи",
  },
  {
    region: "Кировская обл.",
    city: "Пижанка",
  },
  {
    region: "Кировская обл.",
    city: "Подосиновец",
  },
  {
    region: "Кировская обл.",
    city: "Санчурск",
  },
  {
    region: "Кировская обл.",
    city: "Свеча",
  },
  {
    region: "Кировская обл.",
    city: "Слободской",
  },
  {
    region: "Кировская обл.",
    city: "Советск",
  },
  {
    region: "Кировская обл.",
    city: "Суна",
  },
  {
    region: "Кировская обл.",
    city: "Тужа",
  },
  {
    region: "Кировская обл.",
    city: "Уни",
  },
  {
    region: "Кировская обл.",
    city: "Уржум",
  },
  {
    region: "Кировская обл.",
    city: "Фаленки",
  },
  {
    region: "Кировская обл.",
    city: "Халтурин",
  },
  {
    region: "Кировская обл.",
    city: "Юрья",
  },
  {
    region: "Кировская обл.",
    city: "Яранск",
  },
  {
    region: "Коми",
    city: "Абезь",
  },
  {
    region: "Коми",
    city: "Айкино",
  },
  {
    region: "Коми",
    city: "Верхняя Инта",
  },
  {
    region: "Коми",
    city: "Визинга",
  },
  {
    region: "Коми",
    city: "Водный",
  },
  {
    region: "Коми",
    city: "Вожаель",
  },
  {
    region: "Коми",
    city: "Воркута",
  },
  {
    region: "Коми",
    city: "Вуктыл",
  },
  {
    region: "Коми",
    city: "Гешарт",
  },
  {
    region: "Коми",
    city: "Елецкий",
  },
  {
    region: "Коми",
    city: "Емва",
  },
  {
    region: "Коми",
    city: "Заполярный",
  },
  {
    region: "Коми",
    city: "Ижма",
  },
  {
    region: "Коми",
    city: "Инта",
  },
  {
    region: "Коми",
    city: "Ираель",
  },
  {
    region: "Коми",
    city: "Каджером",
  },
  {
    region: "Коми",
    city: "Кажым",
  },
  {
    region: "Коми",
    city: "Кожым",
  },
  {
    region: "Коми",
    city: "Койгородок",
  },
  {
    region: "Коми",
    city: "Корткерос",
  },
  {
    region: "Коми",
    city: "Кослан",
  },
  {
    region: "Коми",
    city: "Объячево",
  },
  {
    region: "Коми",
    city: "Печора",
  },
  {
    region: "Коми",
    city: "Сосногорск",
  },
  {
    region: "Коми",
    city: "Сыктывкар",
  },
  {
    region: "Коми",
    city: "Троицко-Печерск",
  },
  {
    region: "Коми",
    city: "Усинск",
  },
  {
    region: "Коми",
    city: "Усогорск",
  },
  {
    region: "Коми",
    city: "Усть-Кулом",
  },
  {
    region: "Коми",
    city: "Усть-Цильма",
  },
  {
    region: "Коми",
    city: "Ухта",
  },
  {
    region: "Костромская обл.",
    city: "Антропово",
  },
  {
    region: "Костромская обл.",
    city: "Боговарово",
  },
  {
    region: "Костромская обл.",
    city: "Буй",
  },
  {
    region: "Костромская обл.",
    city: "Волгореченск",
  },
  {
    region: "Костромская обл.",
    city: "Галич",
  },
  {
    region: "Костромская обл.",
    city: "Горчуха",
  },
  {
    region: "Костромская обл.",
    city: "Зебляки",
  },
  {
    region: "Костромская обл.",
    city: "Кадый",
  },
  {
    region: "Костромская обл.",
    city: "Кологрив",
  },
  {
    region: "Костромская обл.",
    city: "Кострома",
  },
  {
    region: "Костромская обл.",
    city: "Красное-на-Волге",
  },
  {
    region: "Костромская обл.",
    city: "Макарьев",
  },
  {
    region: "Костромская обл.",
    city: "Мантурово",
  },
  {
    region: "Костромская обл.",
    city: "Нерехта",
  },
  {
    region: "Костромская обл.",
    city: "Нея",
  },
  {
    region: "Костромская обл.",
    city: "Островское",
  },
  {
    region: "Костромская обл.",
    city: "Павино",
  },
  {
    region: "Костромская обл.",
    city: "Парфентьево",
  },
  {
    region: "Костромская обл.",
    city: "Поназырево",
  },
  {
    region: "Костромская обл.",
    city: "Солигалич",
  },
  {
    region: "Костромская обл.",
    city: "Судиславль",
  },
  {
    region: "Костромская обл.",
    city: "Сусанино",
  },
  {
    region: "Костромская обл.",
    city: "Чухлома",
  },
  {
    region: "Костромская обл.",
    city: "Шарья",
  },
  {
    region: "Костромская обл.",
    city: "Шемятино",
  },
  {
    region: "Краснодарский край",
    city: "Абинск",
  },
  {
    region: "Краснодарский край",
    city: "Абрау-Дюрсо",
  },
  {
    region: "Краснодарский край",
    city: "Анапа",
  },
  {
    region: "Краснодарский край",
    city: "Апшеронск",
  },
  {
    region: "Краснодарский край",
    city: "Армавир",
  },
  {
    region: "Краснодарский край",
    city: "Архипо-Осиповка",
  },
  {
    region: "Краснодарский край",
    city: "Афипский",
  },
  {
    region: "Краснодарский край",
    city: "Ахтырский",
  },
  {
    region: "Краснодарский край",
    city: "Ачуево",
  },
  {
    region: "Краснодарский край",
    city: "Белореченск",
  },
  {
    region: "Краснодарский край",
    city: "Верхнебаканский",
  },
  {
    region: "Краснодарский край",
    city: "Выселки",
  },
  {
    region: "Краснодарский край",
    city: "Геленджик",
  },
  {
    region: "Краснодарский край",
    city: "Гиагинская",
  },
  {
    region: "Краснодарский край",
    city: "Горячий Ключ",
  },
  {
    region: "Краснодарский край",
    city: "Джубга",
  },
  {
    region: "Краснодарский край",
    city: "Динская",
  },
  {
    region: "Краснодарский край",
    city: "Ейск",
  },
  {
    region: "Краснодарский край",
    city: "Ильский",
  },
  {
    region: "Краснодарский край",
    city: "Кабардинка",
  },
  {
    region: "Краснодарский край",
    city: "Калинино",
  },
  {
    region: "Краснодарский край",
    city: "Калининская",
  },
  {
    region: "Краснодарский край",
    city: "Каменномостский",
  },
  {
    region: "Краснодарский край",
    city: "Каневская",
  },
  {
    region: "Краснодарский край",
    city: "Кореновск",
  },
  {
    region: "Краснодарский край",
    city: "Красноармейская",
  },
  {
    region: "Краснодарский край",
    city: "Краснодар",
  },
  {
    region: "Краснодарский край",
    city: "Кропоткин",
  },
  {
    region: "Краснодарский край",
    city: "Крыловская",
  },
  {
    region: "Краснодарский край",
    city: "Крымск",
  },
  {
    region: "Краснодарский край",
    city: "Курганинск",
  },
  {
    region: "Краснодарский край",
    city: "Кущевская",
  },
  {
    region: "Краснодарский край",
    city: "Лабинск",
  },
  {
    region: "Краснодарский край",
    city: "Лениградская",
  },
  {
    region: "Краснодарский край",
    city: "Майкоп",
  },
  {
    region: "Краснодарский край",
    city: "Мостовской",
  },
  {
    region: "Краснодарский край",
    city: "Новороссийск",
  },
  {
    region: "Краснодарский край",
    city: "Отрадная",
  },
  {
    region: "Краснодарский край",
    city: "Павловская",
  },
  {
    region: "Краснодарский край",
    city: "Приморско-Ахтарск",
  },
  {
    region: "Краснодарский край",
    city: "Северская",
  },
  {
    region: "Краснодарский край",
    city: "Славянск-на-Кубани",
  },
  {
    region: "Краснодарский край",
    city: "Сочи",
  },
  {
    region: "Краснодарский край",
    city: "Староминская",
  },
  {
    region: "Краснодарский край",
    city: "Старощербиновская",
  },
  {
    region: "Краснодарский край",
    city: "Тбилисская",
  },
  {
    region: "Краснодарский край",
    city: "Темрюк",
  },
  {
    region: "Краснодарский край",
    city: "Тимашевск",
  },
  {
    region: "Краснодарский край",
    city: "Тихорецк",
  },
  {
    region: "Краснодарский край",
    city: "Туапсе",
  },
  {
    region: "Краснодарский край",
    city: "Тульский",
  },
  {
    region: "Краснодарский край",
    city: "Усть-Лабинск",
  },
  {
    region: "Краснодарский край",
    city: "Шовгеновский",
  },
  {
    region: "Красноярский край",
    city: " Железногорск",
  },
  {
    region: "Красноярский край",
    city: "Абаза",
  },
  {
    region: "Красноярский край",
    city: "Абакан",
  },
  {
    region: "Красноярский край",
    city: "Абан",
  },
  {
    region: "Красноярский край",
    city: "Агинское",
  },
  {
    region: "Красноярский край",
    city: "Артемовск",
  },
  {
    region: "Красноярский край",
    city: "Аскиз",
  },
  {
    region: "Красноярский край",
    city: "Ачинск",
  },
  {
    region: "Красноярский край",
    city: "Байкит",
  },
  {
    region: "Красноярский край",
    city: "Балахта",
  },
  {
    region: "Красноярский край",
    city: "Балыкса",
  },
  {
    region: "Красноярский край",
    city: "Белый Яр",
  },
  {
    region: "Красноярский край",
    city: "Бельтырский",
  },
  {
    region: "Красноярский край",
    city: "Бея",
  },
  {
    region: "Красноярский край",
    city: "Бискамжа",
  },
  {
    region: "Красноярский край",
    city: "Боготол",
  },
  {
    region: "Красноярский край",
    city: "Боград",
  },
  {
    region: "Красноярский край",
    city: "Богучаны",
  },
  {
    region: "Красноярский край",
    city: "Большая Мурта",
  },
  {
    region: "Красноярский край",
    city: "Большой Улуй",
  },
  {
    region: "Красноярский край",
    city: "Бородино",
  },
  {
    region: "Красноярский край",
    city: "Ванавара",
  },
  {
    region: "Красноярский край",
    city: "Верхнеимбатск",
  },
  {
    region: "Красноярский край",
    city: "Горячегорск",
  },
  {
    region: "Красноярский край",
    city: "Дзержинское",
  },
  {
    region: "Красноярский край",
    city: "Дивногорск",
  },
  {
    region: "Красноярский край",
    city: "Диксон",
  },
  {
    region: "Красноярский край",
    city: "Дудинка",
  },
  {
    region: "Красноярский край",
    city: "Емельяново",
  },
  {
    region: "Красноярский край",
    city: "Енисейск",
  },
  {
    region: "Красноярский край",
    city: "Ермаковское",
  },
  {
    region: "Красноярский край",
    city: "Заозерный",
  },
  {
    region: "Красноярский край",
    city: "Зеленогорск",
  },
  {
    region: "Красноярский край",
    city: "Игарка",
  },
  {
    region: "Красноярский край",
    city: "Идринское",
  },
  {
    region: "Красноярский край",
    city: "Иланский",
  },
  {
    region: "Красноярский край",
    city: "Ирбейское",
  },
  {
    region: "Красноярский край",
    city: "Казачинское",
  },
  {
    region: "Красноярский край",
    city: "Канск",
  },
  {
    region: "Красноярский край",
    city: "Каратузское",
  },
  {
    region: "Красноярский край",
    city: "Караул",
  },
  {
    region: "Красноярский край",
    city: "Кежма",
  },
  {
    region: "Красноярский край",
    city: "Кодинск",
  },
  {
    region: "Красноярский край",
    city: "Козулька",
  },
  {
    region: "Красноярский край",
    city: "Копьево",
  },
  {
    region: "Красноярский край",
    city: "Краснотуранск",
  },
  {
    region: "Красноярский край",
    city: "Красноярск",
  },
  {
    region: "Красноярский край",
    city: "Курагино",
  },
  {
    region: "Красноярский край",
    city: "Лесосибирск",
  },
  {
    region: "Красноярский край",
    city: "Минусинск",
  },
  {
    region: "Красноярский край",
    city: "Мотыгино",
  },
  {
    region: "Красноярский край",
    city: "Назарово",
  },
  {
    region: "Красноярский край",
    city: "Нижний Ингаш",
  },
  {
    region: "Красноярский край",
    city: "Новоселово",
  },
  {
    region: "Красноярский край",
    city: "Норильск",
  },
  {
    region: "Красноярский край",
    city: "Партизанское",
  },
  {
    region: "Красноярский край",
    city: "Пировское",
  },
  {
    region: "Красноярский край",
    city: "Саяногорск",
  },
  {
    region: "Красноярский край",
    city: "Северо-Енисейский",
  },
  {
    region: "Красноярский край",
    city: "Сосновоборск",
  },
  {
    region: "Красноярский край",
    city: "Тасеево",
  },
  {
    region: "Красноярский край",
    city: "Таштып",
  },
  {
    region: "Красноярский край",
    city: "Тура",
  },
  {
    region: "Красноярский край",
    city: "Туруханск",
  },
  {
    region: "Красноярский край",
    city: "Тюхтет",
  },
  {
    region: "Красноярский край",
    city: "Ужур",
  },
  {
    region: "Красноярский край",
    city: "Усть-Авам",
  },
  {
    region: "Красноярский край",
    city: "Уяр",
  },
  {
    region: "Красноярский край",
    city: "Хатанга",
  },
  {
    region: "Красноярский край",
    city: "Черемушки",
  },
  {
    region: "Красноярский край",
    city: "Черногорск",
  },
  {
    region: "Красноярский край",
    city: "Шалинское",
  },
  {
    region: "Красноярский край",
    city: "Шарыпово",
  },
  {
    region: "Красноярский край",
    city: "Шира",
  },
  {
    region: "Красноярский край",
    city: "Шушенское",
  },
  {
    region: "Курганская обл.",
    city: "Варгаши",
  },
  {
    region: "Курганская обл.",
    city: "Глядянское",
  },
  {
    region: "Курганская обл.",
    city: "Далматово",
  },
  {
    region: "Курганская обл.",
    city: "Каргаполье",
  },
  {
    region: "Курганская обл.",
    city: "Катайск",
  },
  {
    region: "Курганская обл.",
    city: "Кетово",
  },
  {
    region: "Курганская обл.",
    city: "Курган",
  },
  {
    region: "Курганская обл.",
    city: "Куртамыш",
  },
  {
    region: "Курганская обл.",
    city: "Лебяжье",
  },
  {
    region: "Курганская обл.",
    city: "Макушино",
  },
  {
    region: "Курганская обл.",
    city: "Мишкино",
  },
  {
    region: "Курганская обл.",
    city: "Мокроусово",
  },
  {
    region: "Курганская обл.",
    city: "Петухово",
  },
  {
    region: "Курганская обл.",
    city: "Половинное",
  },
  {
    region: "Курганская обл.",
    city: "Сафакулево",
  },
  {
    region: "Курганская обл.",
    city: "Целинное",
  },
  {
    region: "Курганская обл.",
    city: "Шадринск",
  },
  {
    region: "Курганская обл.",
    city: "Шатрово",
  },
  {
    region: "Курганская обл.",
    city: "Шумиха",
  },
  {
    region: "Курганская обл.",
    city: "Щучье",
  },
  {
    region: "Курганская обл.",
    city: "Юргамыш",
  },
  {
    region: "Курская обл.",
    city: "Альменево",
  },
  {
    region: "Курская обл.",
    city: "Белая",
  },
  {
    region: "Курская обл.",
    city: "Большое Солдатское",
  },
  {
    region: "Курская обл.",
    city: "Глушково",
  },
  {
    region: "Курская обл.",
    city: "Горшечное",
  },
  {
    region: "Курская обл.",
    city: "Дмитриев-Льговский",
  },
  {
    region: "Курская обл.",
    city: "Железногорск",
  },
  {
    region: "Курская обл.",
    city: "Золотухино",
  },
  {
    region: "Курская обл.",
    city: "Касторное",
  },
  {
    region: "Курская обл.",
    city: "Конышевка",
  },
  {
    region: "Курская обл.",
    city: "Коренево",
  },
  {
    region: "Курская обл.",
    city: "Курск",
  },
  {
    region: "Курская обл.",
    city: "Курчатов",
  },
  {
    region: "Курская обл.",
    city: "Кшенский",
  },
  {
    region: "Курская обл.",
    city: "Льгов",
  },
  {
    region: "Курская обл.",
    city: "Мантурово",
  },
  {
    region: "Курская обл.",
    city: "Медвенка",
  },
  {
    region: "Курская обл.",
    city: "Обоянь",
  },
  {
    region: "Курская обл.",
    city: "Поныри",
  },
  {
    region: "Курская обл.",
    city: "Пристень",
  },
  {
    region: "Курская обл.",
    city: "Прямицыно",
  },
  {
    region: "Курская обл.",
    city: "Рыльск",
  },
  {
    region: "Курская обл.",
    city: "Суджа",
  },
  {
    region: "Курская обл.",
    city: "Тим",
  },
  {
    region: "Курская обл.",
    city: "Фатеж",
  },
  {
    region: "Курская обл.",
    city: "Хомутовка",
  },
  {
    region: "Курская обл.",
    city: "Черемисиново",
  },
  {
    region: "Курская обл.",
    city: "Щигры",
  },
  {
    region: "Липецкая обл.",
    city: "Грязи",
  },
  {
    region: "Липецкая обл.",
    city: "Данхов",
  },
  {
    region: "Липецкая обл.",
    city: "Доброе",
  },
  {
    region: "Липецкая обл.",
    city: "Долгоруково",
  },
  {
    region: "Липецкая обл.",
    city: "Елец",
  },
  {
    region: "Липецкая обл.",
    city: "Задонск",
  },
  {
    region: "Липецкая обл.",
    city: "Измалково",
  },
  {
    region: "Липецкая обл.",
    city: "Казинка",
  },
  {
    region: "Липецкая обл.",
    city: "Лебедянь",
  },
  {
    region: "Липецкая обл.",
    city: "Лев Толстой",
  },
  {
    region: "Липецкая обл.",
    city: "Липецк",
  },
  {
    region: "Липецкая обл.",
    city: "Тербуны",
  },
  {
    region: "Липецкая обл.",
    city: "Усмань",
  },
  {
    region: "Липецкая обл.",
    city: "Хлевное",
  },
  {
    region: "Липецкая обл.",
    city: "Чаплыгин",
  },
  {
    region: "Магаданская обл.",
    city: "Анадырь",
  },
  {
    region: "Магаданская обл.",
    city: "Атка",
  },
  {
    region: "Магаданская обл.",
    city: "Балыгычан",
  },
  {
    region: "Магаданская обл.",
    city: "Беринговский",
  },
  {
    region: "Магаданская обл.",
    city: "Билибино",
  },
  {
    region: "Магаданская обл.",
    city: "Большевик",
  },
  {
    region: "Магаданская обл.",
    city: "Ванкарем",
  },
  {
    region: "Магаданская обл.",
    city: "Иульитин",
  },
  {
    region: "Магаданская обл.",
    city: "Кадыкчан",
  },
  {
    region: "Магаданская обл.",
    city: "Лаврентия",
  },
  {
    region: "Магаданская обл.",
    city: "Магадан",
  },
  {
    region: "Магаданская обл.",
    city: "Мыс Шмидта",
  },
  {
    region: "Магаданская обл.",
    city: "Ола",
  },
  {
    region: "Магаданская обл.",
    city: "Омонск",
  },
  {
    region: "Магаданская обл.",
    city: "Омсукчан",
  },
  {
    region: "Магаданская обл.",
    city: "Палатка",
  },
  {
    region: "Магаданская обл.",
    city: "Певек",
  },
  {
    region: "Магаданская обл.",
    city: "Провидения",
  },
  {
    region: "Магаданская обл.",
    city: "Сеймчан",
  },
  {
    region: "Магаданская обл.",
    city: "Синегорье",
  },
  {
    region: "Магаданская обл.",
    city: "Сусуман",
  },
  {
    region: "Магаданская обл.",
    city: "Усть-Белая",
  },
  {
    region: "Магаданская обл.",
    city: "Усть-Омчуг",
  },
  {
    region: "Магаданская обл.",
    city: "Эвенск",
  },
  {
    region: "Магаданская обл.",
    city: "Эгвекинот",
  },
  {
    region: "Магаданская обл.",
    city: "Ягодное",
  },
  {
    region: "Марий Эл",
    city: "Волжск",
  },
  {
    region: "Марий Эл",
    city: "Дубовский",
  },
  {
    region: "Марий Эл",
    city: "Звенигово",
  },
  {
    region: "Марий Эл",
    city: "Йошкар-Ола",
  },
  {
    region: "Марий Эл",
    city: "Килемары",
  },
  {
    region: "Марий Эл",
    city: "Козьмодемьянск",
  },
  {
    region: "Марий Эл",
    city: "Куженер",
  },
  {
    region: "Марий Эл",
    city: "Мари-Турек",
  },
  {
    region: "Марий Эл",
    city: "Медведево",
  },
  {
    region: "Марий Эл",
    city: "Морки",
  },
  {
    region: "Марий Эл",
    city: "Новый Торьял",
  },
  {
    region: "Марий Эл",
    city: "Оршанка",
  },
  {
    region: "Марий Эл",
    city: "Параньга",
  },
  {
    region: "Марий Эл",
    city: "Сернур",
  },
  {
    region: "Марий Эл",
    city: "Советский",
  },
  {
    region: "Марий Эл",
    city: "Юрино",
  },
  {
    region: "Мордовия",
    city: "Ардатов",
  },
  {
    region: "Мордовия",
    city: "Атюрьево",
  },
  {
    region: "Мордовия",
    city: "Атяшево",
  },
  {
    region: "Мордовия",
    city: "Большие Березники",
  },
  {
    region: "Мордовия",
    city: "Большое Игнатово",
  },
  {
    region: "Мордовия",
    city: "Выша",
  },
  {
    region: "Мордовия",
    city: "Ельники",
  },
  {
    region: "Мордовия",
    city: "Зубова Поляна",
  },
  {
    region: "Мордовия",
    city: "Инсар",
  },
  {
    region: "Мордовия",
    city: "Кадошкино",
  },
  {
    region: "Мордовия",
    city: "Кемля",
  },
  {
    region: "Мордовия",
    city: "Ковылкино",
  },
  {
    region: "Мордовия",
    city: "Комсомольский",
  },
  {
    region: "Мордовия",
    city: "Кочкурово",
  },
  {
    region: "Мордовия",
    city: "Краснослободск",
  },
  {
    region: "Мордовия",
    city: "Лямбирь",
  },
  {
    region: "Мордовия",
    city: "Ромоданово",
  },
  {
    region: "Мордовия",
    city: "Рузаевка",
  },
  {
    region: "Мордовия",
    city: "Саранск",
  },
  {
    region: "Мордовия",
    city: "Старое Шайгово",
  },
  {
    region: "Мордовия",
    city: "Темников",
  },
  {
    region: "Мордовия",
    city: "Теньгушево",
  },
  {
    region: "Мордовия",
    city: "Торбеево",
  },
  {
    region: "Мордовия",
    city: "Чамзинка",
  },
  {
    region: "Мурманская обл.",
    city: "Апатиты",
  },
  {
    region: "Мурманская обл.",
    city: "Африканда",
  },
  {
    region: "Мурманская обл.",
    city: "Верхнетуломский",
  },
  {
    region: "Мурманская обл.",
    city: "Заозерск",
  },
  {
    region: "Мурманская обл.",
    city: "Заполярный",
  },
  {
    region: "Мурманская обл.",
    city: "Зареченск",
  },
  {
    region: "Мурманская обл.",
    city: "Зашеек",
  },
  {
    region: "Мурманская обл.",
    city: "Зеленоборский",
  },
  {
    region: "Мурманская обл.",
    city: "Кандалакша",
  },
  {
    region: "Мурманская обл.",
    city: "Кильдинстрой",
  },
  {
    region: "Мурманская обл.",
    city: "Кировск",
  },
  {
    region: "Мурманская обл.",
    city: "Ковдор",
  },
  {
    region: "Мурманская обл.",
    city: "Кола",
  },
  {
    region: "Мурманская обл.",
    city: "Конда",
  },
  {
    region: "Мурманская обл.",
    city: "Мончегорск",
  },
  {
    region: "Мурманская обл.",
    city: "Мурманск",
  },
  {
    region: "Мурманская обл.",
    city: "Мурмаши",
  },
  {
    region: "Мурманская обл.",
    city: "Никель",
  },
  {
    region: "Мурманская обл.",
    city: "Оленегорск",
  },
  {
    region: "Мурманская обл.",
    city: "Полярные Зори",
  },
  {
    region: "Мурманская обл.",
    city: "Полярный",
  },
  {
    region: "Мурманская обл.",
    city: "Североморск",
  },
  {
    region: "Мурманская обл.",
    city: "Снежногорск",
  },
  {
    region: "Мурманская обл.",
    city: "Умба",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Ардатов",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Арзамас",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Арья",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Балахна",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Богородск",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Большереченск",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Большое Болдино",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Большое Козино",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Большое Мурашкино",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Большое Пикино",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Бор",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Бутурлино",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Вад",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Варнавино",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Васильсурск",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Вахтан",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Вача",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Велетьма",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Ветлуга",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Виля",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Вознесенское",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Володарск",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Воротынец",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Ворсма",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Воскресенское",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Выездное",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Выкса",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Гагино",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Гидроторф",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Горбатов",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Горбатовка",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Городец",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Горький",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Дальнее Константиново",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Дзержинск",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Дивеево",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Досчатое",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Заволжье",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Катунки",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Керженец",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Княгинино",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Ковернино",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Красные Баки",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Кстово",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Кулебаки",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Лукоянов",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Лысково",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Навашино",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Нижний Новгород",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Павлово",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Первомайск",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Перевоз",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Пильна",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Починки",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Саров",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Сергач",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Сеченово",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Сосновское",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Спасское",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Тонкино",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Тоншаево",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Уразовка",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Урень",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Чкаловск",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Шаранга",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Шатки",
  },
  {
    region: "Нижегородская (Горьковская)",
    city: "Шахунья",
  },
  {
    region: "Новгородская обл.",
    city: "Анциферово",
  },
  {
    region: "Новгородская обл.",
    city: "Батецкий",
  },
  {
    region: "Новгородская обл.",
    city: "Большая Вишера",
  },
  {
    region: "Новгородская обл.",
    city: "Боровичи",
  },
  {
    region: "Новгородская обл.",
    city: "Валдай",
  },
  {
    region: "Новгородская обл.",
    city: "Волот",
  },
  {
    region: "Новгородская обл.",
    city: "Деманск",
  },
  {
    region: "Новгородская обл.",
    city: "Зарубино",
  },
  {
    region: "Новгородская обл.",
    city: "Кресцы",
  },
  {
    region: "Новгородская обл.",
    city: "Любытино",
  },
  {
    region: "Новгородская обл.",
    city: "Малая Вишера",
  },
  {
    region: "Новгородская обл.",
    city: "Марево",
  },
  {
    region: "Новгородская обл.",
    city: "Мошенское",
  },
  {
    region: "Новгородская обл.",
    city: "Новгород",
  },
  {
    region: "Новгородская обл.",
    city: "Окуловка",
  },
  {
    region: "Новгородская обл.",
    city: "Парфино",
  },
  {
    region: "Новгородская обл.",
    city: "Пестово",
  },
  {
    region: "Новгородская обл.",
    city: "Поддорье",
  },
  {
    region: "Новгородская обл.",
    city: "Сольцы",
  },
  {
    region: "Новгородская обл.",
    city: "Старая Русса",
  },
  {
    region: "Новгородская обл.",
    city: "Хвойное",
  },
  {
    region: "Новгородская обл.",
    city: "Холм",
  },
  {
    region: "Новгородская обл.",
    city: "Чудово",
  },
  {
    region: "Новгородская обл.",
    city: "Шимск",
  },
  {
    region: "Новосибирская обл.",
    city: "Баган",
  },
  {
    region: "Новосибирская обл.",
    city: "Барабинск",
  },
  {
    region: "Новосибирская обл.",
    city: "Бердск",
  },
  {
    region: "Новосибирская обл.",
    city: "Биаза",
  },
  {
    region: "Новосибирская обл.",
    city: "Болотное",
  },
  {
    region: "Новосибирская обл.",
    city: "Венгерово",
  },
  {
    region: "Новосибирская обл.",
    city: "Довольное",
  },
  {
    region: "Новосибирская обл.",
    city: "Завьялово",
  },
  {
    region: "Новосибирская обл.",
    city: "Искитим",
  },
  {
    region: "Новосибирская обл.",
    city: "Карасук",
  },
  {
    region: "Новосибирская обл.",
    city: "Каргат",
  },
  {
    region: "Новосибирская обл.",
    city: "Колывань",
  },
  {
    region: "Новосибирская обл.",
    city: "Краснозерское",
  },
  {
    region: "Новосибирская обл.",
    city: "Крутиха",
  },
  {
    region: "Новосибирская обл.",
    city: "Куйбышев",
  },
  {
    region: "Новосибирская обл.",
    city: "Купино",
  },
  {
    region: "Новосибирская обл.",
    city: "Кыштовка",
  },
  {
    region: "Новосибирская обл.",
    city: "Маслянино",
  },
  {
    region: "Новосибирская обл.",
    city: "Михайловский",
  },
  {
    region: "Новосибирская обл.",
    city: "Мошково",
  },
  {
    region: "Новосибирская обл.",
    city: "Новосибирск",
  },
  {
    region: "Новосибирская обл.",
    city: "Ордынское",
  },
  {
    region: "Новосибирская обл.",
    city: "Северное",
  },
  {
    region: "Новосибирская обл.",
    city: "Сузун",
  },
  {
    region: "Новосибирская обл.",
    city: "Татарск",
  },
  {
    region: "Новосибирская обл.",
    city: "Тогучин",
  },
  {
    region: "Новосибирская обл.",
    city: "Убинское",
  },
  {
    region: "Новосибирская обл.",
    city: "Усть-Тарка",
  },
  {
    region: "Новосибирская обл.",
    city: "Чаны",
  },
  {
    region: "Новосибирская обл.",
    city: "Черепаново",
  },
  {
    region: "Новосибирская обл.",
    city: "Чистоозерное",
  },
  {
    region: "Новосибирская обл.",
    city: "Чулым",
  },
  {
    region: "Омская обл.",
    city: "Береговой",
  },
  {
    region: "Омская обл.",
    city: "Большеречье",
  },
  {
    region: "Омская обл.",
    city: "Большие Уки",
  },
  {
    region: "Омская обл.",
    city: "Горьковское",
  },
  {
    region: "Омская обл.",
    city: "Знаменское",
  },
  {
    region: "Омская обл.",
    city: "Исилькуль",
  },
  {
    region: "Омская обл.",
    city: "Калачинск",
  },
  {
    region: "Омская обл.",
    city: "Колосовка",
  },
  {
    region: "Омская обл.",
    city: "Кормиловка",
  },
  {
    region: "Омская обл.",
    city: "Крутинка",
  },
  {
    region: "Омская обл.",
    city: "Любинский",
  },
  {
    region: "Омская обл.",
    city: "Марьяновка",
  },
  {
    region: "Омская обл.",
    city: "Муромцево",
  },
  {
    region: "Омская обл.",
    city: "Называевск",
  },
  {
    region: "Омская обл.",
    city: "Нижняя Омка",
  },
  {
    region: "Омская обл.",
    city: "Нововаршавка",
  },
  {
    region: "Омская обл.",
    city: "Одесское",
  },
  {
    region: "Омская обл.",
    city: "Оконешниково",
  },
  {
    region: "Омская обл.",
    city: "Омск",
  },
  {
    region: "Омская обл.",
    city: "Павлоградка",
  },
  {
    region: "Омская обл.",
    city: "Полтавка",
  },
  {
    region: "Омская обл.",
    city: "Русская Поляна",
  },
  {
    region: "Омская обл.",
    city: "Саргатское",
  },
  {
    region: "Омская обл.",
    city: "Седельниково",
  },
  {
    region: "Омская обл.",
    city: "Таврическое",
  },
  {
    region: "Омская обл.",
    city: "Тара",
  },
  {
    region: "Омская обл.",
    city: "Тевриз",
  },
  {
    region: "Омская обл.",
    city: "Тюкалинск",
  },
  {
    region: "Омская обл.",
    city: "Усть-Ишим",
  },
  {
    region: "Омская обл.",
    city: "Черлак",
  },
  {
    region: "Омская обл.",
    city: "Шербакуль",
  },
  {
    region: "Оренбургская обл.",
    city: "Абдулино",
  },
  {
    region: "Оренбургская обл.",
    city: "Адамовка",
  },
  {
    region: "Оренбургская обл.",
    city: "Айдырлинский",
  },
  {
    region: "Оренбургская обл.",
    city: "Акбулак",
  },
  {
    region: "Оренбургская обл.",
    city: "Аккермановка",
  },
  {
    region: "Оренбургская обл.",
    city: "Асекеево",
  },
  {
    region: "Оренбургская обл.",
    city: "Беляевка",
  },
  {
    region: "Оренбургская обл.",
    city: "Бугуруслан",
  },
  {
    region: "Оренбургская обл.",
    city: "Бузулук",
  },
  {
    region: "Оренбургская обл.",
    city: "Гай",
  },
  {
    region: "Оренбургская обл.",
    city: "Грачевка",
  },
  {
    region: "Оренбургская обл.",
    city: "Домбаровский",
  },
  {
    region: "Оренбургская обл.",
    city: "Дубенский",
  },
  {
    region: "Оренбургская обл.",
    city: "Илек",
  },
  {
    region: "Оренбургская обл.",
    city: "Ириклинский",
  },
  {
    region: "Оренбургская обл.",
    city: "Кувандык",
  },
  {
    region: "Оренбургская обл.",
    city: "Курманаевка",
  },
  {
    region: "Оренбургская обл.",
    city: "Матвеевка",
  },
  {
    region: "Оренбургская обл.",
    city: "Медногорск",
  },
  {
    region: "Оренбургская обл.",
    city: "Новоорск",
  },
  {
    region: "Оренбургская обл.",
    city: "Новосергиевка",
  },
  {
    region: "Оренбургская обл.",
    city: "Новотроицк",
  },
  {
    region: "Оренбургская обл.",
    city: "Октябрьское",
  },
  {
    region: "Оренбургская обл.",
    city: "Оренбург",
  },
  {
    region: "Оренбургская обл.",
    city: "Орск",
  },
  {
    region: "Оренбургская обл.",
    city: "Первомайский",
  },
  {
    region: "Оренбургская обл.",
    city: "Переволоцкий",
  },
  {
    region: "Оренбургская обл.",
    city: "Пономаревка",
  },
  {
    region: "Оренбургская обл.",
    city: "Саракташ",
  },
  {
    region: "Оренбургская обл.",
    city: "Светлый",
  },
  {
    region: "Оренбургская обл.",
    city: "Северное",
  },
  {
    region: "Оренбургская обл.",
    city: "Соль-Илецк",
  },
  {
    region: "Оренбургская обл.",
    city: "Сорочинск",
  },
  {
    region: "Оренбургская обл.",
    city: "Ташла",
  },
  {
    region: "Оренбургская обл.",
    city: "Тоцкое",
  },
  {
    region: "Оренбургская обл.",
    city: "Тюльган",
  },
  {
    region: "Оренбургская обл.",
    city: "Шарлык",
  },
  {
    region: "Оренбургская обл.",
    city: "Энергетик",
  },
  {
    region: "Оренбургская обл.",
    city: "Ясный",
  },
  {
    region: "Орловская обл.",
    city: "Болхов",
  },
  {
    region: "Орловская обл.",
    city: "Верховье",
  },
  {
    region: "Орловская обл.",
    city: "Глазуновка",
  },
  {
    region: "Орловская обл.",
    city: "Дмитровск-Орловский",
  },
  {
    region: "Орловская обл.",
    city: "Долгое",
  },
  {
    region: "Орловская обл.",
    city: "Залегощь",
  },
  {
    region: "Орловская обл.",
    city: "Змиевка",
  },
  {
    region: "Орловская обл.",
    city: "Знаменское",
  },
  {
    region: "Орловская обл.",
    city: "Колпны",
  },
  {
    region: "Орловская обл.",
    city: "Красная Заря",
  },
  {
    region: "Орловская обл.",
    city: "Кромы",
  },
  {
    region: "Орловская обл.",
    city: "Ливны",
  },
  {
    region: "Орловская обл.",
    city: "Малоархангельск",
  },
  {
    region: "Орловская обл.",
    city: "Мценск",
  },
  {
    region: "Орловская обл.",
    city: "Нарышкино",
  },
  {
    region: "Орловская обл.",
    city: "Новосиль",
  },
  {
    region: "Орловская обл.",
    city: "Орел",
  },
  {
    region: "Орловская обл.",
    city: "Покровское",
  },
  {
    region: "Орловская обл.",
    city: "Сосково",
  },
  {
    region: "Орловская обл.",
    city: "Тросна",
  },
  {
    region: "Орловская обл.",
    city: "Хомутово",
  },
  {
    region: "Орловская обл.",
    city: "Хотынец",
  },
  {
    region: "Орловская обл.",
    city: "Шаблыкино",
  },
  {
    region: "Пензенская обл.",
    city: "Башмаково",
  },
  {
    region: "Пензенская обл.",
    city: "Беднодемьяновск",
  },
  {
    region: "Пензенская обл.",
    city: "Беково",
  },
  {
    region: "Пензенская обл.",
    city: "Белинский",
  },
  {
    region: "Пензенская обл.",
    city: "Бессоновка",
  },
  {
    region: "Пензенская обл.",
    city: "Вадинск",
  },
  {
    region: "Пензенская обл.",
    city: "Верхозим",
  },
  {
    region: "Пензенская обл.",
    city: "Городище",
  },
  {
    region: "Пензенская обл.",
    city: "Евлашево",
  },
  {
    region: "Пензенская обл.",
    city: "Земетчино",
  },
  {
    region: "Пензенская обл.",
    city: "Золотаревка",
  },
  {
    region: "Пензенская обл.",
    city: "Исса",
  },
  {
    region: "Пензенская обл.",
    city: "Каменка",
  },
  {
    region: "Пензенская обл.",
    city: "Колышлей",
  },
  {
    region: "Пензенская обл.",
    city: "Кондоль",
  },
  {
    region: "Пензенская обл.",
    city: "Кузнецк",
  },
  {
    region: "Пензенская обл.",
    city: "Лопатино",
  },
  {
    region: "Пензенская обл.",
    city: "Малая Сердоба",
  },
  {
    region: "Пензенская обл.",
    city: "Мокшан",
  },
  {
    region: "Пензенская обл.",
    city: "Наровчат",
  },
  {
    region: "Пензенская обл.",
    city: "Неверкино",
  },
  {
    region: "Пензенская обл.",
    city: "Нижний Ломов",
  },
  {
    region: "Пензенская обл.",
    city: "Никольск",
  },
  {
    region: "Пензенская обл.",
    city: "Пачелма",
  },
  {
    region: "Пензенская обл.",
    city: "Пенза",
  },
  {
    region: "Пензенская обл.",
    city: "Русский Камешкир",
  },
  {
    region: "Пензенская обл.",
    city: "Сердобск",
  },
  {
    region: "Пензенская обл.",
    city: "Сосновоборск",
  },
  {
    region: "Пензенская обл.",
    city: "Сура",
  },
  {
    region: "Пензенская обл.",
    city: "Тамала",
  },
  {
    region: "Пензенская обл.",
    city: "Шемышейка",
  },
  {
    region: "Пермская обл.",
    city: "Барда",
  },
  {
    region: "Пермская обл.",
    city: "Березники",
  },
  {
    region: "Пермская обл.",
    city: "Большая Соснова",
  },
  {
    region: "Пермская обл.",
    city: "Верещагино",
  },
  {
    region: "Пермская обл.",
    city: "Гайны",
  },
  {
    region: "Пермская обл.",
    city: "Горнозаводск",
  },
  {
    region: "Пермская обл.",
    city: "Гремячинск",
  },
  {
    region: "Пермская обл.",
    city: "Губаха",
  },
  {
    region: "Пермская обл.",
    city: "Добрянка",
  },
  {
    region: "Пермская обл.",
    city: "Елово",
  },
  {
    region: "Пермская обл.",
    city: "Зюкайка",
  },
  {
    region: "Пермская обл.",
    city: "Ильинский",
  },
  {
    region: "Пермская обл.",
    city: "Карагай",
  },
  {
    region: "Пермская обл.",
    city: "Керчевский",
  },
  {
    region: "Пермская обл.",
    city: "Кизел",
  },
  {
    region: "Пермская обл.",
    city: "Коса",
  },
  {
    region: "Пермская обл.",
    city: "Кочево",
  },
  {
    region: "Пермская обл.",
    city: "Красновишерск",
  },
  {
    region: "Пермская обл.",
    city: "Краснокамск",
  },
  {
    region: "Пермская обл.",
    city: "Кудымкар",
  },
  {
    region: "Пермская обл.",
    city: "Куеда",
  },
  {
    region: "Пермская обл.",
    city: "Кунгур",
  },
  {
    region: "Пермская обл.",
    city: "Лысьва",
  },
  {
    region: "Пермская обл.",
    city: "Ныроб",
  },
  {
    region: "Пермская обл.",
    city: "Нытва",
  },
  {
    region: "Пермская обл.",
    city: "Октябрьский",
  },
  {
    region: "Пермская обл.",
    city: "Орда",
  },
  {
    region: "Пермская обл.",
    city: "Оса",
  },
  {
    region: "Пермская обл.",
    city: "Оханск",
  },
  {
    region: "Пермская обл.",
    city: "Очер",
  },
  {
    region: "Пермская обл.",
    city: "Пермь",
  },
  {
    region: "Пермская обл.",
    city: "Соликамск",
  },
  {
    region: "Пермская обл.",
    city: "Суксун",
  },
  {
    region: "Пермская обл.",
    city: "Уинское",
  },
  {
    region: "Пермская обл.",
    city: "Усолье",
  },
  {
    region: "Пермская обл.",
    city: "Усть-Кишерть",
  },
  {
    region: "Пермская обл.",
    city: "Чайковский",
  },
  {
    region: "Пермская обл.",
    city: "Частые",
  },
  {
    region: "Пермская обл.",
    city: "Чердынь",
  },
  {
    region: "Пермская обл.",
    city: "Чернореченский",
  },
  {
    region: "Пермская обл.",
    city: "Чернушка",
  },
  {
    region: "Пермская обл.",
    city: "Чусовой",
  },
  {
    region: "Пермская обл.",
    city: "Юрла",
  },
  {
    region: "Пермская обл.",
    city: "Юсьва",
  },
  {
    region: "Приморский край",
    city: "Анучино",
  },
  {
    region: "Приморский край",
    city: "Арсеньев",
  },
  {
    region: "Приморский край",
    city: "Артем",
  },
  {
    region: "Приморский край",
    city: "Артемовский",
  },
  {
    region: "Приморский край",
    city: "Большой Камень",
  },
  {
    region: "Приморский край",
    city: "Валентин",
  },
  {
    region: "Приморский край",
    city: "Владивосток",
  },
  {
    region: "Приморский край",
    city: "Высокогорск",
  },
  {
    region: "Приморский край",
    city: "Горные Ключи",
  },
  {
    region: "Приморский край",
    city: "Горный",
  },
  {
    region: "Приморский край",
    city: "Дальнегорск",
  },
  {
    region: "Приморский край",
    city: "Дальнереченск",
  },
  {
    region: "Приморский край",
    city: "Зарубино",
  },
  {
    region: "Приморский край",
    city: "Кавалерово",
  },
  {
    region: "Приморский край",
    city: "Каменка",
  },
  {
    region: "Приморский край",
    city: "Камень-Рыболов",
  },
  {
    region: "Приморский край",
    city: "Кировский",
  },
  {
    region: "Приморский край",
    city: "Лазо",
  },
  {
    region: "Приморский край",
    city: "Лесозаводск",
  },
  {
    region: "Приморский край",
    city: "Лучегорск",
  },
  {
    region: "Приморский край",
    city: "Михайловка",
  },
  {
    region: "Приморский край",
    city: "Находка",
  },
  {
    region: "Приморский край",
    city: "Новопокровка",
  },
  {
    region: "Приморский край",
    city: "Ольга",
  },
  {
    region: "Приморский край",
    city: "Партизанск",
  },
  {
    region: "Приморский край",
    city: "Пограничный",
  },
  {
    region: "Приморский край",
    city: "Покровка",
  },
  {
    region: "Приморский край",
    city: "Русский",
  },
  {
    region: "Приморский край",
    city: "Самарга",
  },
  {
    region: "Приморский край",
    city: "Славянка",
  },
  {
    region: "Приморский край",
    city: "Спасск-Дальний",
  },
  {
    region: "Приморский край",
    city: "Терней",
  },
  {
    region: "Приморский край",
    city: "Уссурийск",
  },
  {
    region: "Приморский край",
    city: "Фокино",
  },
  {
    region: "Приморский край",
    city: "Хасан",
  },
  {
    region: "Приморский край",
    city: "Хороль",
  },
  {
    region: "Приморский край",
    city: "Черниговка",
  },
  {
    region: "Приморский край",
    city: "Чугуевка",
  },
  {
    region: "Приморский край",
    city: "Яковлевка",
  },
  {
    region: "Псковская обл.",
    city: "Бежаницы",
  },
  {
    region: "Псковская обл.",
    city: "Великие Луки",
  },
  {
    region: "Псковская обл.",
    city: "Гдов",
  },
  {
    region: "Псковская обл.",
    city: "Дедовичи",
  },
  {
    region: "Псковская обл.",
    city: "Дно",
  },
  {
    region: "Псковская обл.",
    city: "Заплюсье",
  },
  {
    region: "Псковская обл.",
    city: "Идрица",
  },
  {
    region: "Псковская обл.",
    city: "Красногородское",
  },
  {
    region: "Псковская обл.",
    city: "Кунья",
  },
  {
    region: "Псковская обл.",
    city: "Локня",
  },
  {
    region: "Псковская обл.",
    city: "Невель",
  },
  {
    region: "Псковская обл.",
    city: "Новоржев",
  },
  {
    region: "Псковская обл.",
    city: "Новосокольники",
  },
  {
    region: "Псковская обл.",
    city: "Опочка",
  },
  {
    region: "Псковская обл.",
    city: "Остров",
  },
  {
    region: "Псковская обл.",
    city: "Палкино",
  },
  {
    region: "Псковская обл.",
    city: "Печоры",
  },
  {
    region: "Псковская обл.",
    city: "Плюсса",
  },
  {
    region: "Псковская обл.",
    city: "Порхов",
  },
  {
    region: "Псковская обл.",
    city: "Псков",
  },
  {
    region: "Псковская обл.",
    city: "Пустошка",
  },
  {
    region: "Псковская обл.",
    city: "Пушкинские Горы",
  },
  {
    region: "Псковская обл.",
    city: "Пыталово",
  },
  {
    region: "Псковская обл.",
    city: "Себеж",
  },
  {
    region: "Псковская обл.",
    city: "Струги-Красные",
  },
  {
    region: "Псковская обл.",
    city: "Усвяты",
  },
  {
    region: "Ростовская обл.",
    city: "Азов",
  },
  {
    region: "Ростовская обл.",
    city: "Аксай",
  },
  {
    region: "Ростовская обл.",
    city: "Алмазный",
  },
  {
    region: "Ростовская обл.",
    city: "Аютинск",
  },
  {
    region: "Ростовская обл.",
    city: "Багаевский",
  },
  {
    region: "Ростовская обл.",
    city: "Батайск",
  },
  {
    region: "Ростовская обл.",
    city: "Белая Калитва",
  },
  {
    region: "Ростовская обл.",
    city: "Боковская",
  },
  {
    region: "Ростовская обл.",
    city: "Большая Мартыновка",
  },
  {
    region: "Ростовская обл.",
    city: "Вешенская",
  },
  {
    region: "Ростовская обл.",
    city: "Волгодонск",
  },
  {
    region: "Ростовская обл.",
    city: "Восход",
  },
  {
    region: "Ростовская обл.",
    city: "Гигант",
  },
  {
    region: "Ростовская обл.",
    city: "Горняцкий",
  },
  {
    region: "Ростовская обл.",
    city: "Гуково",
  },
  {
    region: "Ростовская обл.",
    city: "Донецк",
  },
  {
    region: "Ростовская обл.",
    city: "Донской",
  },
  {
    region: "Ростовская обл.",
    city: "Дубовское",
  },
  {
    region: "Ростовская обл.",
    city: "Егорлыкская",
  },
  {
    region: "Ростовская обл.",
    city: "Жирнов",
  },
  {
    region: "Ростовская обл.",
    city: "Заветное",
  },
  {
    region: "Ростовская обл.",
    city: "Заводской",
  },
  {
    region: "Ростовская обл.",
    city: "Зверево",
  },
  {
    region: "Ростовская обл.",
    city: "Зерноград",
  },
  {
    region: "Ростовская обл.",
    city: "Зимовники",
  },
  {
    region: "Ростовская обл.",
    city: "Кагальницкая",
  },
  {
    region: "Ростовская обл.",
    city: "Казанская",
  },
  {
    region: "Ростовская обл.",
    city: "Каменоломни",
  },
  {
    region: "Ростовская обл.",
    city: "Каменск-Шахтинский",
  },
  {
    region: "Ростовская обл.",
    city: "Кашары",
  },
  {
    region: "Ростовская обл.",
    city: "Коксовый",
  },
  {
    region: "Ростовская обл.",
    city: "Константиновск",
  },
  {
    region: "Ростовская обл.",
    city: "Красный Сулин",
  },
  {
    region: "Ростовская обл.",
    city: "Куйбышево",
  },
  {
    region: "Ростовская обл.",
    city: "Матвеев Курган",
  },
  {
    region: "Ростовская обл.",
    city: "Мигулинская",
  },
  {
    region: "Ростовская обл.",
    city: "Миллерово",
  },
  {
    region: "Ростовская обл.",
    city: "Милютинская",
  },
  {
    region: "Ростовская обл.",
    city: "Морозовск",
  },
  {
    region: "Ростовская обл.",
    city: "Новочеркасск",
  },
  {
    region: "Ростовская обл.",
    city: "Новошахтинск",
  },
  {
    region: "Ростовская обл.",
    city: "Обливская",
  },
  {
    region: "Ростовская обл.",
    city: "Орловский",
  },
  {
    region: "Ростовская обл.",
    city: "Песчанокопское",
  },
  {
    region: "Ростовская обл.",
    city: "Покровское",
  },
  {
    region: "Ростовская обл.",
    city: "Пролетарск",
  },
  {
    region: "Ростовская обл.",
    city: "Ремонтное",
  },
  {
    region: "Ростовская обл.",
    city: "Родионово-Несветайская",
  },
  {
    region: "Ростовская обл.",
    city: "Ростов-на-Дону",
  },
  {
    region: "Ростовская обл.",
    city: "Сальск",
  },
  {
    region: "Ростовская обл.",
    city: "Семикаракорск",
  },
  {
    region: "Ростовская обл.",
    city: "Таганрог",
  },
  {
    region: "Ростовская обл.",
    city: "Тарасовский",
  },
  {
    region: "Ростовская обл.",
    city: "Тацинский",
  },
  {
    region: "Ростовская обл.",
    city: "Усть-Донецкий",
  },
  {
    region: "Ростовская обл.",
    city: "Целина",
  },
  {
    region: "Ростовская обл.",
    city: "Цимлянск",
  },
  {
    region: "Ростовская обл.",
    city: "Чалтырь",
  },
  {
    region: "Ростовская обл.",
    city: "Чертково",
  },
  {
    region: "Ростовская обл.",
    city: "Шахты",
  },
  {
    region: "Ростовская обл.",
    city: "Шолоховский",
  },
  {
    region: "Рязанская обл.",
    city: "Александро-Невский",
  },
  {
    region: "Рязанская обл.",
    city: "Горняк",
  },
  {
    region: "Рязанская обл.",
    city: "Гусь Железный",
  },
  {
    region: "Рязанская обл.",
    city: "Елатьма",
  },
  {
    region: "Рязанская обл.",
    city: "Ермишь",
  },
  {
    region: "Рязанская обл.",
    city: "Заречный",
  },
  {
    region: "Рязанская обл.",
    city: "Захарово",
  },
  {
    region: "Рязанская обл.",
    city: "Кадом",
  },
  {
    region: "Рязанская обл.",
    city: "Касимов",
  },
  {
    region: "Рязанская обл.",
    city: "Кораблино",
  },
  {
    region: "Рязанская обл.",
    city: "Милославское",
  },
  {
    region: "Рязанская обл.",
    city: "Михайлов",
  },
  {
    region: "Рязанская обл.",
    city: "Пителино",
  },
  {
    region: "Рязанская обл.",
    city: "Пронск",
  },
  {
    region: "Рязанская обл.",
    city: "Путятино",
  },
  {
    region: "Рязанская обл.",
    city: "Рыбное",
  },
  {
    region: "Рязанская обл.",
    city: "Ряжск",
  },
  {
    region: "Рязанская обл.",
    city: "Рязань",
  },
  {
    region: "Рязанская обл.",
    city: "Сапожок",
  },
  {
    region: "Рязанская обл.",
    city: "Сараи",
  },
  {
    region: "Рязанская обл.",
    city: "Сасово",
  },
  {
    region: "Рязанская обл.",
    city: "Скопин",
  },
  {
    region: "Рязанская обл.",
    city: "Спас-Клепики",
  },
  {
    region: "Рязанская обл.",
    city: "Спасск-Рязанский",
  },
  {
    region: "Рязанская обл.",
    city: "Старожилово",
  },
  {
    region: "Рязанская обл.",
    city: "Ухолово",
  },
  {
    region: "Рязанская обл.",
    city: "Чучково",
  },
  {
    region: "Рязанская обл.",
    city: "Шацк",
  },
  {
    region: "Рязанская обл.",
    city: "Шилово",
  },
  {
    region: "Самарская обл.",
    city: "Алексеевка",
  },
  {
    region: "Самарская обл.",
    city: "Безенчук",
  },
  {
    region: "Самарская обл.",
    city: "Богатое",
  },
  {
    region: "Самарская обл.",
    city: "Богатырь",
  },
  {
    region: "Самарская обл.",
    city: "Большая Глущица",
  },
  {
    region: "Самарская обл.",
    city: "Большая Черниговка",
  },
  {
    region: "Самарская обл.",
    city: "Борское",
  },
  {
    region: "Самарская обл.",
    city: "Волжский",
  },
  {
    region: "Самарская обл.",
    city: "Жигулевск",
  },
  {
    region: "Самарская обл.",
    city: "Зольное",
  },
  {
    region: "Самарская обл.",
    city: "Исаклы",
  },
  {
    region: "Самарская обл.",
    city: "Камышла",
  },
  {
    region: "Самарская обл.",
    city: "Кинель",
  },
  {
    region: "Самарская обл.",
    city: "Кинель-Черкасы",
  },
  {
    region: "Самарская обл.",
    city: "Клявлино",
  },
  {
    region: "Самарская обл.",
    city: "Кошки",
  },
  {
    region: "Самарская обл.",
    city: "Красноармейское",
  },
  {
    region: "Самарская обл.",
    city: "Красный Яр",
  },
  {
    region: "Самарская обл.",
    city: "Куйбышев",
  },
  {
    region: "Самарская обл.",
    city: "Нефтегорск",
  },
  {
    region: "Самарская обл.",
    city: "Новокуйбышевск",
  },
  {
    region: "Самарская обл.",
    city: "Октябрьск",
  },
  {
    region: "Самарская обл.",
    city: "Отрадный",
  },
  {
    region: "Самарская обл.",
    city: "Пестравка",
  },
  {
    region: "Самарская обл.",
    city: "Похвистнево",
  },
  {
    region: "Самарская обл.",
    city: "Приволжье",
  },
  {
    region: "Самарская обл.",
    city: "Самара",
  },
  {
    region: "Самарская обл.",
    city: "Сургут (Самарская обл.)",
  },
  {
    region: "Самарская обл.",
    city: "Сызрань",
  },
  {
    region: "Самарская обл.",
    city: "Тольятти",
  },
  {
    region: "Самарская обл.",
    city: "Хворостянка",
  },
  {
    region: "Самарская обл.",
    city: "Чапаевск",
  },
  {
    region: "Самарская обл.",
    city: "Челно-Вершины",
  },
  {
    region: "Самарская обл.",
    city: "Шентала",
  },
  {
    region: "Самарская обл.",
    city: "Шигоны",
  },
  {
    region: "Саратовская обл.",
    city: "Александров Гай",
  },
  {
    region: "Саратовская обл.",
    city: "Аркадак",
  },
  {
    region: "Саратовская обл.",
    city: "Аткарск",
  },
  {
    region: "Саратовская обл.",
    city: "Базарный Карабулак",
  },
  {
    region: "Саратовская обл.",
    city: "Балаково",
  },
  {
    region: "Саратовская обл.",
    city: "Балашов",
  },
  {
    region: "Саратовская обл.",
    city: "Балтай",
  },
  {
    region: "Саратовская обл.",
    city: "Возрождение",
  },
  {
    region: "Саратовская обл.",
    city: "Вольск",
  },
  {
    region: "Саратовская обл.",
    city: "Воскресенское",
  },
  {
    region: "Саратовская обл.",
    city: "Горный",
  },
  {
    region: "Саратовская обл.",
    city: "Дергачи",
  },
  {
    region: "Саратовская обл.",
    city: "Духовницкое",
  },
  {
    region: "Саратовская обл.",
    city: "Екатериновка",
  },
  {
    region: "Саратовская обл.",
    city: "Ершов",
  },
  {
    region: "Саратовская обл.",
    city: "Заречный",
  },
  {
    region: "Саратовская обл.",
    city: "Ивантеевка",
  },
  {
    region: "Саратовская обл.",
    city: "Калининск",
  },
  {
    region: "Саратовская обл.",
    city: "Каменский",
  },
  {
    region: "Саратовская обл.",
    city: "Красноармейск",
  },
  {
    region: "Саратовская обл.",
    city: "Красный Кут",
  },
  {
    region: "Саратовская обл.",
    city: "Лысые Горы",
  },
  {
    region: "Саратовская обл.",
    city: "Маркс",
  },
  {
    region: "Саратовская обл.",
    city: "Мокроус",
  },
  {
    region: "Саратовская обл.",
    city: "Новоузенск",
  },
  {
    region: "Саратовская обл.",
    city: "Новые Бурасы",
  },
  {
    region: "Саратовская обл.",
    city: "Озинки",
  },
  {
    region: "Саратовская обл.",
    city: "Перелюб",
  },
  {
    region: "Саратовская обл.",
    city: "Петровск",
  },
  {
    region: "Саратовская обл.",
    city: "Питерка",
  },
  {
    region: "Саратовская обл.",
    city: "Пугачев",
  },
  {
    region: "Саратовская обл.",
    city: "Ровное",
  },
  {
    region: "Саратовская обл.",
    city: "Романовка",
  },
  {
    region: "Саратовская обл.",
    city: "Ртищево",
  },
  {
    region: "Саратовская обл.",
    city: "Самойловка",
  },
  {
    region: "Саратовская обл.",
    city: "Саратов",
  },
  {
    region: "Саратовская обл.",
    city: "Степное",
  },
  {
    region: "Саратовская обл.",
    city: "Татищево",
  },
  {
    region: "Саратовская обл.",
    city: "Турки",
  },
  {
    region: "Саратовская обл.",
    city: "Хвалынск",
  },
  {
    region: "Саратовская обл.",
    city: "Энгельс",
  },
  {
    region: "Саха (Якутия)",
    city: "Абый",
  },
  {
    region: "Саха (Якутия)",
    city: "Алдан",
  },
  {
    region: "Саха (Якутия)",
    city: "Амга",
  },
  {
    region: "Саха (Якутия)",
    city: "Батагай",
  },
  {
    region: "Саха (Якутия)",
    city: "Бердигестях",
  },
  {
    region: "Саха (Якутия)",
    city: "Беркакит",
  },
  {
    region: "Саха (Якутия)",
    city: "Бестях",
  },
  {
    region: "Саха (Якутия)",
    city: "Борогонцы",
  },
  {
    region: "Саха (Якутия)",
    city: "Верхневилюйск",
  },
  {
    region: "Саха (Якутия)",
    city: "Верхнеколымск",
  },
  {
    region: "Саха (Якутия)",
    city: "Верхоянск",
  },
  {
    region: "Саха (Якутия)",
    city: "Вилюйск",
  },
  {
    region: "Саха (Якутия)",
    city: "Витим",
  },
  {
    region: "Саха (Якутия)",
    city: "Власово",
  },
  {
    region: "Саха (Якутия)",
    city: "Жиганск",
  },
  {
    region: "Саха (Якутия)",
    city: "Зырянка",
  },
  {
    region: "Саха (Якутия)",
    city: "Кангалассы",
  },
  {
    region: "Саха (Якутия)",
    city: "Канкунский",
  },
  {
    region: "Саха (Якутия)",
    city: "Ленск",
  },
  {
    region: "Саха (Якутия)",
    city: "Майя",
  },
  {
    region: "Саха (Якутия)",
    city: "Менкеря",
  },
  {
    region: "Саха (Якутия)",
    city: "Мирный",
  },
  {
    region: "Саха (Якутия)",
    city: "Нерюнгри",
  },
  {
    region: "Саха (Якутия)",
    city: "Нычалах",
  },
  {
    region: "Саха (Якутия)",
    city: "Нюрба",
  },
  {
    region: "Саха (Якутия)",
    city: "Олекминск",
  },
  {
    region: "Саха (Якутия)",
    city: "Покровск",
  },
  {
    region: "Саха (Якутия)",
    city: "Сангар",
  },
  {
    region: "Саха (Якутия)",
    city: "Саскылах",
  },
  {
    region: "Саха (Якутия)",
    city: "Среднеколымск",
  },
  {
    region: "Саха (Якутия)",
    city: "Сунтар",
  },
  {
    region: "Саха (Якутия)",
    city: "Тикси",
  },
  {
    region: "Саха (Якутия)",
    city: "Усть-Мая",
  },
  {
    region: "Саха (Якутия)",
    city: "Усть-Нера",
  },
  {
    region: "Саха (Якутия)",
    city: "Хандыга",
  },
  {
    region: "Саха (Якутия)",
    city: "Хонуу",
  },
  {
    region: "Саха (Якутия)",
    city: "Черский",
  },
  {
    region: "Саха (Якутия)",
    city: "Чокурдах",
  },
  {
    region: "Саха (Якутия)",
    city: "Чурапча",
  },
  {
    region: "Саха (Якутия)",
    city: "Якутск",
  },
  {
    region: "Сахалин",
    city: "Александровск-Сахалинский",
  },
  {
    region: "Сахалин",
    city: "Анбэцу",
  },
  {
    region: "Сахалин",
    city: "Анива",
  },
  {
    region: "Сахалин",
    city: "Бошняково",
  },
  {
    region: "Сахалин",
    city: "Быков",
  },
  {
    region: "Сахалин",
    city: "Вахрушев",
  },
  {
    region: "Сахалин",
    city: "Взморье",
  },
  {
    region: "Сахалин",
    city: "Гастелло",
  },
  {
    region: "Сахалин",
    city: "Горнозаводск",
  },
  {
    region: "Сахалин",
    city: "Долинск",
  },
  {
    region: "Сахалин",
    city: "Ильинский",
  },
  {
    region: "Сахалин",
    city: "Катангли",
  },
  {
    region: "Сахалин",
    city: "Корсаков",
  },
  {
    region: "Сахалин",
    city: "Курильск",
  },
  {
    region: "Сахалин",
    city: "Макаров",
  },
  {
    region: "Сахалин",
    city: "Невельск",
  },
  {
    region: "Сахалин",
    city: "Ноглики",
  },
  {
    region: "Сахалин",
    city: "Оха",
  },
  {
    region: "Сахалин",
    city: "Поронайск",
  },
  {
    region: "Сахалин",
    city: "Северо-Курильск",
  },
  {
    region: "Сахалин",
    city: "Смирных",
  },
  {
    region: "Сахалин",
    city: "Томари",
  },
  {
    region: "Сахалин",
    city: "Тымовское",
  },
  {
    region: "Сахалин",
    city: "Углегорск",
  },
  {
    region: "Сахалин",
    city: "Холмск",
  },
  {
    region: "Сахалин",
    city: "Шахтерск",
  },
  {
    region: "Сахалин",
    city: "Южно-Курильск",
  },
  {
    region: "Сахалин",
    city: "Южно-Сахалинск",
  },
  {
    region: "Свердловская обл.",
    city: "Алапаевск",
  },
  {
    region: "Свердловская обл.",
    city: "Алтынай",
  },
  {
    region: "Свердловская обл.",
    city: "Арамиль",
  },
  {
    region: "Свердловская обл.",
    city: "Артемовский",
  },
  {
    region: "Свердловская обл.",
    city: "Арти",
  },
  {
    region: "Свердловская обл.",
    city: "Асбест",
  },
  {
    region: "Свердловская обл.",
    city: "Ачит",
  },
  {
    region: "Свердловская обл.",
    city: "Байкалово",
  },
  {
    region: "Свердловская обл.",
    city: "Басьяновский",
  },
  {
    region: "Свердловская обл.",
    city: "Белоярский",
  },
  {
    region: "Свердловская обл.",
    city: "Березовский",
  },
  {
    region: "Свердловская обл.",
    city: "Богданович",
  },
  {
    region: "Свердловская обл.",
    city: "Буланаш",
  },
  {
    region: "Свердловская обл.",
    city: "Верхний Тагил",
  },
  {
    region: "Свердловская обл.",
    city: "Верхняя Пышма",
  },
  {
    region: "Свердловская обл.",
    city: "Верхняя Салда",
  },
  {
    region: "Свердловская обл.",
    city: "Верхняя Синячиха",
  },
  {
    region: "Свердловская обл.",
    city: "Верхняя Сысерть",
  },
  {
    region: "Свердловская обл.",
    city: "Верхняя Тура",
  },
  {
    region: "Свердловская обл.",
    city: "Верхотурье",
  },
  {
    region: "Свердловская обл.",
    city: "Висим",
  },
  {
    region: "Свердловская обл.",
    city: "Волчанск",
  },
  {
    region: "Свердловская обл.",
    city: "Воронцовка",
  },
  {
    region: "Свердловская обл.",
    city: "Гари",
  },
  {
    region: "Свердловская обл.",
    city: "Дегтярск",
  },
  {
    region: "Свердловская обл.",
    city: "Екатеринбург",
  },
  {
    region: "Свердловская обл.",
    city: "Ертарский",
  },
  {
    region: "Свердловская обл.",
    city: "Заводоуспенское",
  },
  {
    region: "Свердловская обл.",
    city: "Зыряновский",
  },
  {
    region: "Свердловская обл.",
    city: "Зюзельский",
  },
  {
    region: "Свердловская обл.",
    city: "Ивдель",
  },
  {
    region: "Свердловская обл.",
    city: "Изумруд",
  },
  {
    region: "Свердловская обл.",
    city: "Ирбит",
  },
  {
    region: "Свердловская обл.",
    city: "Ис",
  },
  {
    region: "Свердловская обл.",
    city: "Каменск-Уральский",
  },
  {
    region: "Свердловская обл.",
    city: "Камышлов",
  },
  {
    region: "Свердловская обл.",
    city: "Карпинск",
  },
  {
    region: "Свердловская обл.",
    city: "Карпунинский",
  },
  {
    region: "Свердловская обл.",
    city: "Качканар",
  },
  {
    region: "Свердловская обл.",
    city: "Кировград",
  },
  {
    region: "Свердловская обл.",
    city: "Краснотурьинск",
  },
  {
    region: "Свердловская обл.",
    city: "Красноуральск",
  },
  {
    region: "Свердловская обл.",
    city: "Красноуфимск",
  },
  {
    region: "Свердловская обл.",
    city: "Кушва",
  },
  {
    region: "Свердловская обл.",
    city: "Лесной",
  },
  {
    region: "Свердловская обл.",
    city: "Михайловск",
  },
  {
    region: "Свердловская обл.",
    city: "Невьянск",
  },
  {
    region: "Свердловская обл.",
    city: "Нижние Серги",
  },
  {
    region: "Свердловская обл.",
    city: "Нижний Тагил",
  },
  {
    region: "Свердловская обл.",
    city: "Нижняя Салда",
  },
  {
    region: "Свердловская обл.",
    city: "Нижняя Тура",
  },
  {
    region: "Свердловская обл.",
    city: "Новая Ляля",
  },
  {
    region: "Свердловская обл.",
    city: "Новоуральск",
  },
  {
    region: "Свердловская обл.",
    city: "Оус",
  },
  {
    region: "Свердловская обл.",
    city: "Первоуральск",
  },
  {
    region: "Свердловская обл.",
    city: "Полевской",
  },
  {
    region: "Свердловская обл.",
    city: "Пышма",
  },
  {
    region: "Свердловская обл.",
    city: "Ревда",
  },
  {
    region: "Свердловская обл.",
    city: "Реж",
  },
  {
    region: "Свердловская обл.",
    city: "Свердловск",
  },
  {
    region: "Свердловская обл.",
    city: "Североуральск",
  },
  {
    region: "Свердловская обл.",
    city: "Серов",
  },
  {
    region: "Свердловская обл.",
    city: "Сосьва",
  },
  {
    region: "Свердловская обл.",
    city: "Среднеуральск",
  },
  {
    region: "Свердловская обл.",
    city: "Сухой Лог",
  },
  {
    region: "Свердловская обл.",
    city: "Сысерть",
  },
  {
    region: "Свердловская обл.",
    city: "Таборы",
  },
  {
    region: "Свердловская обл.",
    city: "Тавда",
  },
  {
    region: "Свердловская обл.",
    city: "Талица",
  },
  {
    region: "Свердловская обл.",
    city: "Тугулым",
  },
  {
    region: "Свердловская обл.",
    city: "Туринск",
  },
  {
    region: "Свердловская обл.",
    city: "Туринская Слобода",
  },
  {
    region: "Северная Осетия",
    city: "Алагир",
  },
  {
    region: "Северная Осетия",
    city: "Ардон",
  },
  {
    region: "Северная Осетия",
    city: "Беслан",
  },
  {
    region: "Северная Осетия",
    city: "Бурон",
  },
  {
    region: "Северная Осетия",
    city: "Владикавказ",
  },
  {
    region: "Северная Осетия",
    city: "Дигора",
  },
  {
    region: "Северная Осетия",
    city: "Моздок",
  },
  {
    region: "Северная Осетия",
    city: "Орджоникидзе",
  },
  {
    region: "Северная Осетия",
    city: "Чикола",
  },
  {
    region: "Смоленская обл.",
    city: "Велиж",
  },
  {
    region: "Смоленская обл.",
    city: "Верхнеднепровский",
  },
  {
    region: "Смоленская обл.",
    city: "Ворга",
  },
  {
    region: "Смоленская обл.",
    city: "Вязьма",
  },
  {
    region: "Смоленская обл.",
    city: "Гагарин",
  },
  {
    region: "Смоленская обл.",
    city: "Глинка",
  },
  {
    region: "Смоленская обл.",
    city: "Голынки",
  },
  {
    region: "Смоленская обл.",
    city: "Демидов",
  },
  {
    region: "Смоленская обл.",
    city: "Десногорск",
  },
  {
    region: "Смоленская обл.",
    city: "Дорогобуж",
  },
  {
    region: "Смоленская обл.",
    city: "Духовщина",
  },
  {
    region: "Смоленская обл.",
    city: "Екимовичи",
  },
  {
    region: "Смоленская обл.",
    city: "Ельня",
  },
  {
    region: "Смоленская обл.",
    city: "Ершичи",
  },
  {
    region: "Смоленская обл.",
    city: "Издешково",
  },
  {
    region: "Смоленская обл.",
    city: "Кардымово",
  },
  {
    region: "Смоленская обл.",
    city: "Красный",
  },
  {
    region: "Смоленская обл.",
    city: "Монастырщина",
  },
  {
    region: "Смоленская обл.",
    city: "Новодугино",
  },
  {
    region: "Смоленская обл.",
    city: "Починок",
  },
  {
    region: "Смоленская обл.",
    city: "Рославль",
  },
  {
    region: "Смоленская обл.",
    city: "Рудня",
  },
  {
    region: "Смоленская обл.",
    city: "Сафоново",
  },
  {
    region: "Смоленская обл.",
    city: "Смоленск",
  },
  {
    region: "Смоленская обл.",
    city: "Сычевка",
  },
  {
    region: "Смоленская обл.",
    city: "Угра",
  },
  {
    region: "Смоленская обл.",
    city: "Хиславичи",
  },
  {
    region: "Смоленская обл.",
    city: "Холм-Жирковский",
  },
  {
    region: "Смоленская обл.",
    city: "Шумячи",
  },
  {
    region: "Смоленская обл.",
    city: "Ярцево",
  },
  {
    region: "Ставропольский край",
    city: "Александровское",
  },
  {
    region: "Ставропольский край",
    city: "Арзгир",
  },
  {
    region: "Ставропольский край",
    city: "Благодарный",
  },
  {
    region: "Ставропольский край",
    city: "Буденновск",
  },
  {
    region: "Ставропольский край",
    city: "Георгиевск",
  },
  {
    region: "Ставропольский край",
    city: "Дивное",
  },
  {
    region: "Ставропольский край",
    city: "Домбай",
  },
  {
    region: "Ставропольский край",
    city: "Донское",
  },
  {
    region: "Ставропольский край",
    city: "Ессентуки",
  },
  {
    region: "Ставропольский край",
    city: "Железноводск",
  },
  {
    region: "Ставропольский край",
    city: "Зеленокумск",
  },
  {
    region: "Ставропольский край",
    city: "Изобильный",
  },
  {
    region: "Ставропольский край",
    city: "Иноземцево",
  },
  {
    region: "Ставропольский край",
    city: "Ипатово",
  },
  {
    region: "Ставропольский край",
    city: "Карачаевск",
  },
  {
    region: "Ставропольский край",
    city: "Кисловодск",
  },
  {
    region: "Ставропольский край",
    city: "Кочубеевское",
  },
  {
    region: "Ставропольский край",
    city: "Красногвардейское",
  },
  {
    region: "Ставропольский край",
    city: "Курсавка",
  },
  {
    region: "Ставропольский край",
    city: "Левокумское",
  },
  {
    region: "Ставропольский край",
    city: "Минеральные Воды",
  },
  {
    region: "Ставропольский край",
    city: "Невинномысск",
  },
  {
    region: "Ставропольский край",
    city: "Нефтекумск",
  },
  {
    region: "Ставропольский край",
    city: "Новоалександровск",
  },
  {
    region: "Ставропольский край",
    city: "Новоалександровская",
  },
  {
    region: "Ставропольский край",
    city: "Новопавловск",
  },
  {
    region: "Ставропольский край",
    city: "Новоселицкое",
  },
  {
    region: "Ставропольский край",
    city: "Преградная",
  },
  {
    region: "Ставропольский край",
    city: "Пятигорск",
  },
  {
    region: "Ставропольский край",
    city: "Светлоград",
  },
  {
    region: "Ставропольский край",
    city: "Солнечнодольск",
  },
  {
    region: "Ставропольский край",
    city: "Ставрополь",
  },
  {
    region: "Ставропольский край",
    city: "Степное",
  },
  {
    region: "Ставропольский край",
    city: "Теберда",
  },
  {
    region: "Ставропольский край",
    city: "Усть-Джегута",
  },
  {
    region: "Ставропольский край",
    city: "Хабез",
  },
  {
    region: "Ставропольский край",
    city: "Черкесск",
  },
  {
    region: "Тамбовская обл.",
    city: "Бондари",
  },
  {
    region: "Тамбовская обл.",
    city: "Гавриловка Вторая",
  },
  {
    region: "Тамбовская обл.",
    city: "Жердевка",
  },
  {
    region: "Тамбовская обл.",
    city: "Знаменка",
  },
  {
    region: "Тамбовская обл.",
    city: "Инжавино",
  },
  {
    region: "Тамбовская обл.",
    city: "Кирсанов",
  },
  {
    region: "Тамбовская обл.",
    city: "Котовск",
  },
  {
    region: "Тамбовская обл.",
    city: "Мичуринск",
  },
  {
    region: "Тамбовская обл.",
    city: "Мордово",
  },
  {
    region: "Тамбовская обл.",
    city: "Моршанск",
  },
  {
    region: "Тамбовская обл.",
    city: "Мучкапский",
  },
  {
    region: "Тамбовская обл.",
    city: "Первомайский",
  },
  {
    region: "Тамбовская обл.",
    city: "Петровское",
  },
  {
    region: "Тамбовская обл.",
    city: "Пичаево",
  },
  {
    region: "Тамбовская обл.",
    city: "Рассказово",
  },
  {
    region: "Тамбовская обл.",
    city: "Ржакса",
  },
  {
    region: "Тамбовская обл.",
    city: "Староюрьево",
  },
  {
    region: "Тамбовская обл.",
    city: "Тамбов",
  },
  {
    region: "Тамбовская обл.",
    city: "Токаревка",
  },
  {
    region: "Тамбовская обл.",
    city: "Уварово",
  },
  {
    region: "Тамбовская обл.",
    city: "Умет",
  },
  {
    region: "Татарстан",
    city: "Агрыз",
  },
  {
    region: "Татарстан",
    city: "Азнакаево",
  },
  {
    region: "Татарстан",
    city: "Аксубаево",
  },
  {
    region: "Татарстан",
    city: "Актюбинский",
  },
  {
    region: "Татарстан",
    city: "Алексеевское",
  },
  {
    region: "Татарстан",
    city: "Альметьевск",
  },
  {
    region: "Татарстан",
    city: "Альметьевск",
  },
  {
    region: "Татарстан",
    city: "Апастово",
  },
  {
    region: "Татарстан",
    city: "Арск",
  },
  {
    region: "Татарстан",
    city: "Бавлы",
  },
  {
    region: "Татарстан",
    city: "Базарные Матаки",
  },
  {
    region: "Татарстан",
    city: "Балтаси",
  },
  {
    region: "Татарстан",
    city: "Богатые Сабы",
  },
  {
    region: "Татарстан",
    city: "Брежнев",
  },
  {
    region: "Татарстан",
    city: "Бугульма",
  },
  {
    region: "Татарстан",
    city: "Буинск",
  },
  {
    region: "Татарстан",
    city: "Васильево",
  },
  {
    region: "Татарстан",
    city: "Верхний Услон",
  },
  {
    region: "Татарстан",
    city: "Высокая Гора",
  },
  {
    region: "Татарстан",
    city: "Дербешкинский",
  },
  {
    region: "Татарстан",
    city: "Елабуга",
  },
  {
    region: "Татарстан",
    city: "Заинск",
  },
  {
    region: "Татарстан",
    city: "Зеленодольск",
  },
  {
    region: "Татарстан",
    city: "Казань",
  },
  {
    region: "Татарстан",
    city: "Камское Устье",
  },
  {
    region: "Татарстан",
    city: "Карабаш",
  },
  {
    region: "Татарстан",
    city: "Куйбышев",
  },
  {
    region: "Татарстан",
    city: "Кукмод",
  },
  {
    region: "Татарстан",
    city: "Кукмор",
  },
  {
    region: "Татарстан",
    city: "Лаишево",
  },
  {
    region: "Татарстан",
    city: "Лениногорск",
  },
  {
    region: "Татарстан",
    city: "Мамадыш",
  },
  {
    region: "Татарстан",
    city: "Менделеевск",
  },
  {
    region: "Татарстан",
    city: "Мензелинск",
  },
  {
    region: "Татарстан",
    city: "Муслюмово",
  },
  {
    region: "Татарстан",
    city: "Набережные Челны",
  },
  {
    region: "Татарстан",
    city: "Нижнекамск",
  },
  {
    region: "Татарстан",
    city: "Новошешминск",
  },
  {
    region: "Татарстан",
    city: "Нурлат",
  },
  {
    region: "Татарстан",
    city: "Пестрецы",
  },
  {
    region: "Татарстан",
    city: "Рыбная Слобода",
  },
  {
    region: "Татарстан",
    city: "Сарманово",
  },
  {
    region: "Татарстан",
    city: "Старое Дрожжаное",
  },
  {
    region: "Татарстан",
    city: "Тетюши",
  },
  {
    region: "Татарстан",
    city: "Чистополь",
  },
  {
    region: "Тверская обл.",
    city: "Андреаполь",
  },
  {
    region: "Тверская обл.",
    city: "Бежецк",
  },
  {
    region: "Тверская обл.",
    city: "Белый",
  },
  {
    region: "Тверская обл.",
    city: "Белый Городок",
  },
  {
    region: "Тверская обл.",
    city: "Березайка",
  },
  {
    region: "Тверская обл.",
    city: "Бологое",
  },
  {
    region: "Тверская обл.",
    city: "Васильевский Мох",
  },
  {
    region: "Тверская обл.",
    city: "Выползово",
  },
  {
    region: "Тверская обл.",
    city: "Вышний Волочек",
  },
  {
    region: "Тверская обл.",
    city: "Жарковский",
  },
  {
    region: "Тверская обл.",
    city: "Западная Двина",
  },
  {
    region: "Тверская обл.",
    city: "Заречье",
  },
  {
    region: "Тверская обл.",
    city: "Зубцов",
  },
  {
    region: "Тверская обл.",
    city: "Изоплит",
  },
  {
    region: "Тверская обл.",
    city: "Калашниково",
  },
  {
    region: "Тверская обл.",
    city: "Калинин",
  },
  {
    region: "Тверская обл.",
    city: "Калязин",
  },
  {
    region: "Тверская обл.",
    city: "Кашин",
  },
  {
    region: "Тверская обл.",
    city: "Кесова Гора",
  },
  {
    region: "Тверская обл.",
    city: "Кимры",
  },
  {
    region: "Тверская обл.",
    city: "Конаково",
  },
  {
    region: "Тверская обл.",
    city: "Красный Холм",
  },
  {
    region: "Тверская обл.",
    city: "Кувшиново",
  },
  {
    region: "Тверская обл.",
    city: "Лесное",
  },
  {
    region: "Тверская обл.",
    city: "Лихославль",
  },
  {
    region: "Тверская обл.",
    city: "Максатиха",
  },
  {
    region: "Тверская обл.",
    city: "Молоково",
  },
  {
    region: "Тверская обл.",
    city: "Нелидово",
  },
  {
    region: "Тверская обл.",
    city: "Оленино",
  },
  {
    region: "Тверская обл.",
    city: "Осташков",
  },
  {
    region: "Тверская обл.",
    city: "Пено",
  },
  {
    region: "Тверская обл.",
    city: "Рамешки",
  },
  {
    region: "Тверская обл.",
    city: "Ржев",
  },
  {
    region: "Тверская обл.",
    city: "Сандово",
  },
  {
    region: "Тверская обл.",
    city: "Селижарово",
  },
  {
    region: "Тверская обл.",
    city: "Сонково",
  },
  {
    region: "Тверская обл.",
    city: "Спирово",
  },
  {
    region: "Тверская обл.",
    city: "Старица",
  },
  {
    region: "Тверская обл.",
    city: "Тверь",
  },
  {
    region: "Тверская обл.",
    city: "Торжок",
  },
  {
    region: "Тверская обл.",
    city: "Торопец",
  },
  {
    region: "Тверская обл.",
    city: "Удомля",
  },
  {
    region: "Тверская обл.",
    city: "Фирово",
  },
  {
    region: "Томская обл.",
    city: "Александровское",
  },
  {
    region: "Томская обл.",
    city: "Асино",
  },
  {
    region: "Томская обл.",
    city: "Бакчар",
  },
  {
    region: "Томская обл.",
    city: "Батурино",
  },
  {
    region: "Томская обл.",
    city: "Белый Яр",
  },
  {
    region: "Томская обл.",
    city: "Зырянское",
  },
  {
    region: "Томская обл.",
    city: "Итатка",
  },
  {
    region: "Томская обл.",
    city: "Каргасок",
  },
  {
    region: "Томская обл.",
    city: "Катайга",
  },
  {
    region: "Томская обл.",
    city: "Кожевниково",
  },
  {
    region: "Томская обл.",
    city: "Колпашево",
  },
  {
    region: "Томская обл.",
    city: "Кривошеино",
  },
  {
    region: "Томская обл.",
    city: "Мельниково",
  },
  {
    region: "Томская обл.",
    city: "Молчаново",
  },
  {
    region: "Томская обл.",
    city: "Парабель",
  },
  {
    region: "Томская обл.",
    city: "Первомайское",
  },
  {
    region: "Томская обл.",
    city: "Подгорное",
  },
  {
    region: "Томская обл.",
    city: "Северск",
  },
  {
    region: "Томская обл.",
    city: "Стрежевой",
  },
  {
    region: "Томская обл.",
    city: "Томск",
  },
  {
    region: "Томская обл.",
    city: "Тымск",
  },
  {
    region: "Тува (Тувинская Респ.)",
    city: "Ак-Довурак",
  },
  {
    region: "Тува (Тувинская Респ.)",
    city: "Бай Хаак",
  },
  {
    region: "Тува (Тувинская Респ.)",
    city: "Кызыл",
  },
  {
    region: "Тува (Тувинская Респ.)",
    city: "Самагалтай",
  },
  {
    region: "Тува (Тувинская Респ.)",
    city: "Сарыг-Сеп",
  },
  {
    region: "Тува (Тувинская Респ.)",
    city: "Суть-Холь",
  },
  {
    region: "Тува (Тувинская Респ.)",
    city: "Тоора-Хем",
  },
  {
    region: "Тува (Тувинская Респ.)",
    city: "Туран",
  },
  {
    region: "Тува (Тувинская Респ.)",
    city: "Тээли",
  },
  {
    region: "Тува (Тувинская Респ.)",
    city: "Хову-Аксы",
  },
  {
    region: "Тува (Тувинская Респ.)",
    city: "Чадан",
  },
  {
    region: "Тува (Тувинская Респ.)",
    city: "Шагонар",
  },
  {
    region: "Тува (Тувинская Респ.)",
    city: "Эрзин",
  },
  {
    region: "Тульская обл.",
    city: "Агеево",
  },
  {
    region: "Тульская обл.",
    city: "Алексин",
  },
  {
    region: "Тульская обл.",
    city: "Арсеньево",
  },
  {
    region: "Тульская обл.",
    city: "Барсуки",
  },
  {
    region: "Тульская обл.",
    city: "Бегичевский",
  },
  {
    region: "Тульская обл.",
    city: "Белев",
  },
  {
    region: "Тульская обл.",
    city: "Богородицк",
  },
  {
    region: "Тульская обл.",
    city: "Болохово",
  },
  {
    region: "Тульская обл.",
    city: "Велегож",
  },
  {
    region: "Тульская обл.",
    city: "Венев",
  },
  {
    region: "Тульская обл.",
    city: "Волово",
  },
  {
    region: "Тульская обл.",
    city: "Горелки",
  },
  {
    region: "Тульская обл.",
    city: "Донской",
  },
  {
    region: "Тульская обл.",
    city: "Дубна",
  },
  {
    region: "Тульская обл.",
    city: "Епифань",
  },
  {
    region: "Тульская обл.",
    city: "Ефремов",
  },
  {
    region: "Тульская обл.",
    city: "Заокский",
  },
  {
    region: "Тульская обл.",
    city: "Казановка",
  },
  {
    region: "Тульская обл.",
    city: "Кимовск",
  },
  {
    region: "Тульская обл.",
    city: "Киреевск",
  },
  {
    region: "Тульская обл.",
    city: "Куркино",
  },
  {
    region: "Тульская обл.",
    city: "Ленинский",
  },
  {
    region: "Тульская обл.",
    city: "Новомосковск",
  },
  {
    region: "Тульская обл.",
    city: "Одоев",
  },
  {
    region: "Тульская обл.",
    city: "Плавск",
  },
  {
    region: "Тульская обл.",
    city: "Суворов",
  },
  {
    region: "Тульская обл.",
    city: "Тула",
  },
  {
    region: "Тульская обл.",
    city: "Узловая",
  },
  {
    region: "Тульская обл.",
    city: "Щекино",
  },
  {
    region: "Тульская обл.",
    city: "Ясногорск",
  },
  {
    region: "Тюменская обл.",
    city: "Абатский",
  },
  {
    region: "Тюменская обл.",
    city: "Аксарка",
  },
  {
    region: "Тюменская обл.",
    city: "Армизонское",
  },
  {
    region: "Тюменская обл.",
    city: "Аромашево",
  },
  {
    region: "Тюменская обл.",
    city: "Белоярский",
  },
  {
    region: "Тюменская обл.",
    city: "Бердюжье",
  },
  {
    region: "Тюменская обл.",
    city: "Большое Сорокино",
  },
  {
    region: "Тюменская обл.",
    city: "Вагай",
  },
  {
    region: "Тюменская обл.",
    city: "Викулово",
  },
  {
    region: "Тюменская обл.",
    city: "Винзили",
  },
  {
    region: "Тюменская обл.",
    city: "Голышманово",
  },
  {
    region: "Тюменская обл.",
    city: "Губкинский",
  },
  {
    region: "Тюменская обл.",
    city: "Заводопетровский",
  },
  {
    region: "Тюменская обл.",
    city: "Заводоуковск",
  },
  {
    region: "Тюменская обл.",
    city: "Излучинск",
  },
  {
    region: "Тюменская обл.",
    city: "Исетское",
  },
  {
    region: "Тюменская обл.",
    city: "Ишим",
  },
  {
    region: "Тюменская обл.",
    city: "Казанское",
  },
  {
    region: "Тюменская обл.",
    city: "Казым-Мыс",
  },
  {
    region: "Тюменская обл.",
    city: "Когалым",
  },
  {
    region: "Тюменская обл.",
    city: "Кондинское",
  },
  {
    region: "Тюменская обл.",
    city: "Красноселькуп",
  },
  {
    region: "Тюменская обл.",
    city: "Лабытнанги",
  },
  {
    region: "Тюменская обл.",
    city: "Ларьяк",
  },
  {
    region: "Тюменская обл.",
    city: "Мегион",
  },
  {
    region: "Тюменская обл.",
    city: "Мужи",
  },
  {
    region: "Тюменская обл.",
    city: "Муравленко",
  },
  {
    region: "Тюменская обл.",
    city: "Надым",
  },
  {
    region: "Тюменская обл.",
    city: "Находка",
  },
  {
    region: "Тюменская обл.",
    city: "Нефтеюганск",
  },
  {
    region: "Тюменская обл.",
    city: "Нижневартовск",
  },
  {
    region: "Тюменская обл.",
    city: "Нижняя Тавда",
  },
  {
    region: "Тюменская обл.",
    city: "Новый Уренгой",
  },
  {
    region: "Тюменская обл.",
    city: "Ноябрьск",
  },
  {
    region: "Тюменская обл.",
    city: "Нягань",
  },
  {
    region: "Тюменская обл.",
    city: "Октябрьское",
  },
  {
    region: "Тюменская обл.",
    city: "Омутинский",
  },
  {
    region: "Тюменская обл.",
    city: "Радужный",
  },
  {
    region: "Тюменская обл.",
    city: "Салехард",
  },
  {
    region: "Тюменская обл.",
    city: "Сладково",
  },
  {
    region: "Тюменская обл.",
    city: "Советский",
  },
  {
    region: "Тюменская обл.",
    city: "Сургут",
  },
  {
    region: "Тюменская обл.",
    city: "Тазовский",
  },
  {
    region: "Тюменская обл.",
    city: "Тарко-Сале",
  },
  {
    region: "Тюменская обл.",
    city: "Тобольск",
  },
  {
    region: "Тюменская обл.",
    city: "Тюмень",
  },
  {
    region: "Тюменская обл.",
    city: "Уват",
  },
  {
    region: "Тюменская обл.",
    city: "Унъюган",
  },
  {
    region: "Тюменская обл.",
    city: "Упорово",
  },
  {
    region: "Тюменская обл.",
    city: "Урай",
  },
  {
    region: "Тюменская обл.",
    city: "Ханты-Мансийск",
  },
  {
    region: "Тюменская обл.",
    city: "Юрибей",
  },
  {
    region: "Тюменская обл.",
    city: "Ялуторовск",
  },
  {
    region: "Тюменская обл.",
    city: "Яр-Сале",
  },
  {
    region: "Тюменская обл.",
    city: "Ярково",
  },
  {
    region: "Удмуртия",
    city: "Алнаши",
  },
  {
    region: "Удмуртия",
    city: "Балезино",
  },
  {
    region: "Удмуртия",
    city: "Вавож",
  },
  {
    region: "Удмуртия",
    city: "Воткинск",
  },
  {
    region: "Удмуртия",
    city: "Глазов",
  },
  {
    region: "Удмуртия",
    city: "Грахово",
  },
  {
    region: "Удмуртия",
    city: "Дебесы",
  },
  {
    region: "Удмуртия",
    city: "Завьялово",
  },
  {
    region: "Удмуртия",
    city: "Игра",
  },
  {
    region: "Удмуртия",
    city: "Ижевск",
  },
  {
    region: "Удмуртия",
    city: "Кама",
  },
  {
    region: "Удмуртия",
    city: "Камбарка",
  },
  {
    region: "Удмуртия",
    city: "Каракулино",
  },
  {
    region: "Удмуртия",
    city: "Кез",
  },
  {
    region: "Удмуртия",
    city: "Кизнер",
  },
  {
    region: "Удмуртия",
    city: "Киясово",
  },
  {
    region: "Удмуртия",
    city: "Красногорское",
  },
  {
    region: "Удмуртия",
    city: "Можга",
  },
  {
    region: "Удмуртия",
    city: "Сарапул",
  },
  {
    region: "Удмуртия",
    city: "Селты",
  },
  {
    region: "Удмуртия",
    city: "Сюмси",
  },
  {
    region: "Удмуртия",
    city: "Ува",
  },
  {
    region: "Удмуртия",
    city: "Устинов",
  },
  {
    region: "Удмуртия",
    city: "Шаркан",
  },
  {
    region: "Удмуртия",
    city: "Юкаменское",
  },
  {
    region: "Удмуртия",
    city: "Якшур-Бодья",
  },
  {
    region: "Удмуртия",
    city: "Яр",
  },
  {
    region: "Ульяновская обл.",
    city: "Базарный Сызган",
  },
  {
    region: "Ульяновская обл.",
    city: "Барыш",
  },
  {
    region: "Ульяновская обл.",
    city: "Большое Нагаткино",
  },
  {
    region: "Ульяновская обл.",
    city: "Вешкайма",
  },
  {
    region: "Ульяновская обл.",
    city: "Глотовка",
  },
  {
    region: "Ульяновская обл.",
    city: "Димитровград",
  },
  {
    region: "Ульяновская обл.",
    city: "Игнатовка",
  },
  {
    region: "Ульяновская обл.",
    city: "Измайлово",
  },
  {
    region: "Ульяновская обл.",
    city: "Инза",
  },
  {
    region: "Ульяновская обл.",
    city: "Ишеевка",
  },
  {
    region: "Ульяновская обл.",
    city: "Канадей",
  },
  {
    region: "Ульяновская обл.",
    city: "Карсун",
  },
  {
    region: "Ульяновская обл.",
    city: "Кузоватово",
  },
  {
    region: "Ульяновская обл.",
    city: "Майна",
  },
  {
    region: "Ульяновская обл.",
    city: "Новая Малыкла",
  },
  {
    region: "Ульяновская обл.",
    city: "Новоспасское",
  },
  {
    region: "Ульяновская обл.",
    city: "Павловка",
  },
  {
    region: "Ульяновская обл.",
    city: "Радищево",
  },
  {
    region: "Ульяновская обл.",
    city: "Сенгилей",
  },
  {
    region: "Ульяновская обл.",
    city: "Старая Кулатка",
  },
  {
    region: "Ульяновская обл.",
    city: "Старая Майна",
  },
  {
    region: "Ульяновская обл.",
    city: "Сурское",
  },
  {
    region: "Ульяновская обл.",
    city: "Тереньга",
  },
  {
    region: "Ульяновская обл.",
    city: "Ульяновск",
  },
  {
    region: "Ульяновская обл.",
    city: "Чердаклы",
  },
  {
    region: "Уральская обл.",
    city: "Аксай",
  },
  {
    region: "Уральская обл.",
    city: "Дарьинское",
  },
  {
    region: "Уральская обл.",
    city: "Деркул",
  },
  {
    region: "Уральская обл.",
    city: "Джамбейты",
  },
  {
    region: "Уральская обл.",
    city: "Джаныбек",
  },
  {
    region: "Уральская обл.",
    city: "Казталовка",
  },
  {
    region: "Уральская обл.",
    city: "Калмыково",
  },
  {
    region: "Уральская обл.",
    city: "Каратобе",
  },
  {
    region: "Уральская обл.",
    city: "Переметное",
  },
  {
    region: "Уральская обл.",
    city: "Сайхин",
  },
  {
    region: "Уральская обл.",
    city: "Уральск",
  },
  {
    region: "Уральская обл.",
    city: "Федоровка",
  },
  {
    region: "Уральская обл.",
    city: "Фурманово",
  },
  {
    region: "Уральская обл.",
    city: "Чапаев",
  },
  {
    region: "Хабаровский край",
    city: "Амурск",
  },
  {
    region: "Хабаровский край",
    city: "Аян",
  },
  {
    region: "Хабаровский край",
    city: "Березовый",
  },
  {
    region: "Хабаровский край",
    city: "Бикин",
  },
  {
    region: "Хабаровский край",
    city: "Бира",
  },
  {
    region: "Хабаровский край",
    city: "Биракан",
  },
  {
    region: "Хабаровский край",
    city: "Богородское",
  },
  {
    region: "Хабаровский край",
    city: "Болонь",
  },
  {
    region: "Хабаровский край",
    city: "Ванино",
  },
  {
    region: "Хабаровский край",
    city: "Волочаевка Вторая",
  },
  {
    region: "Хабаровский край",
    city: "Высокогорный",
  },
  {
    region: "Хабаровский край",
    city: "Вяземский",
  },
  {
    region: "Хабаровский край",
    city: "Горный",
  },
  {
    region: "Хабаровский край",
    city: "Гурское",
  },
  {
    region: "Хабаровский край",
    city: "Дормидонтовка",
  },
  {
    region: "Хабаровский край",
    city: "Заветы Ильича",
  },
  {
    region: "Хабаровский край",
    city: "Известковый",
  },
  {
    region: "Хабаровский край",
    city: "Иннокентьевка",
  },
  {
    region: "Хабаровский край",
    city: "Комсомольск-на-Амуре",
  },
  {
    region: "Хабаровский край",
    city: "Ленинское",
  },
  {
    region: "Хабаровский край",
    city: "Нелькан",
  },
  {
    region: "Хабаровский край",
    city: "Николаевск-на-Амуре",
  },
  {
    region: "Хабаровский край",
    city: "Облучье",
  },
  {
    region: "Хабаровский край",
    city: "Охотск",
  },
  {
    region: "Хабаровский край",
    city: "Переяславка",
  },
  {
    region: "Хабаровский край",
    city: "Смидович",
  },
  {
    region: "Хабаровский край",
    city: "Советская Гавань",
  },
  {
    region: "Хабаровский край",
    city: "Софийск",
  },
  {
    region: "Хабаровский край",
    city: "Троицкое",
  },
  {
    region: "Хабаровский край",
    city: "Тугур",
  },
  {
    region: "Хабаровский край",
    city: "Хабаровск",
  },
  {
    region: "Хабаровский край",
    city: "Чегдомын",
  },
  {
    region: "Хабаровский край",
    city: "Чумикан",
  },
  {
    region: "Хакасия",
    city: "Абакан",
  },
  {
    region: "Хакасия",
    city: "Саяногорск",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Аган",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Игрим",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Излучинск",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Лангепас",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Лянтор",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Мегион",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Нефтеюганск",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Нижневартовск",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Нягань",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Покачи",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Приобье",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Пыть-Ях",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Радужный",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Сургут",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Урай",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Ханты-Мансийск",
  },
  {
    region: "Ханты-Мансийский АО",
    city: "Югорск",
  },
  {
    region: "Челябинская обл.",
    city: "Агаповка",
  },
  {
    region: "Челябинская обл.",
    city: "Аргаяш",
  },
  {
    region: "Челябинская обл.",
    city: "Аша",
  },
  {
    region: "Челябинская обл.",
    city: "Бакал",
  },
  {
    region: "Челябинская обл.",
    city: "Бреды",
  },
  {
    region: "Челябинская обл.",
    city: "Варна",
  },
  {
    region: "Челябинская обл.",
    city: "Верхнеуральск",
  },
  {
    region: "Челябинская обл.",
    city: "Верхний Уфалей",
  },
  {
    region: "Челябинская обл.",
    city: "Еманжелинск",
  },
  {
    region: "Челябинская обл.",
    city: "Златоуст",
  },
  {
    region: "Челябинская обл.",
    city: "Карабаш",
  },
  {
    region: "Челябинская обл.",
    city: "Карталы",
  },
  {
    region: "Челябинская обл.",
    city: "Касли",
  },
  {
    region: "Челябинская обл.",
    city: "Катав-Ивановск",
  },
  {
    region: "Челябинская обл.",
    city: "Копейск",
  },
  {
    region: "Челябинская обл.",
    city: "Коркино",
  },
  {
    region: "Челябинская обл.",
    city: "Кунашак",
  },
  {
    region: "Челябинская обл.",
    city: "Куса",
  },
  {
    region: "Челябинская обл.",
    city: "Кыштым",
  },
  {
    region: "Челябинская обл.",
    city: "Магнитогорск",
  },
  {
    region: "Челябинская обл.",
    city: "Миасс",
  },
  {
    region: "Челябинская обл.",
    city: "Озерск",
  },
  {
    region: "Челябинская обл.",
    city: "Октябрьское",
  },
  {
    region: "Челябинская обл.",
    city: "Пласт",
  },
  {
    region: "Челябинская обл.",
    city: "Сатка",
  },
  {
    region: "Челябинская обл.",
    city: "Сим",
  },
  {
    region: "Челябинская обл.",
    city: "Снежинск",
  },
  {
    region: "Челябинская обл.",
    city: "Трехгорный",
  },
  {
    region: "Челябинская обл.",
    city: "Троицк",
  },
  {
    region: "Челябинская обл.",
    city: "Увельский",
  },
  {
    region: "Челябинская обл.",
    city: "Уйское",
  },
  {
    region: "Челябинская обл.",
    city: "Усть-Катав",
  },
  {
    region: "Челябинская обл.",
    city: "Фершампенуаз",
  },
  {
    region: "Челябинская обл.",
    city: "Чебаркуль",
  },
  {
    region: "Челябинская обл.",
    city: "Челябинск",
  },
  {
    region: "Челябинская обл.",
    city: "Чесма",
  },
  {
    region: "Челябинская обл.",
    city: "Южно-Уральск",
  },
  {
    region: "Челябинская обл.",
    city: "Юрюзань",
  },
  {
    region: "Чечено-Ингушетия",
    city: "Аргун",
  },
  {
    region: "Чечено-Ингушетия",
    city: "Грозный",
  },
  {
    region: "Чечено-Ингушетия",
    city: "Гудермез",
  },
  {
    region: "Чечено-Ингушетия",
    city: "Малгобек",
  },
  {
    region: "Чечено-Ингушетия",
    city: "Назрань",
  },
  {
    region: "Чечено-Ингушетия",
    city: "Наурская",
  },
  {
    region: "Чечено-Ингушетия",
    city: "Ножай-Юрт",
  },
  {
    region: "Чечено-Ингушетия",
    city: "Орджоникидзевская",
  },
  {
    region: "Чечено-Ингушетия",
    city: "Советское",
  },
  {
    region: "Чечено-Ингушетия",
    city: "Урус-Мартан",
  },
  {
    region: "Чечено-Ингушетия",
    city: "Шали",
  },
  {
    region: "Читинская обл.",
    city: "Агинское",
  },
  {
    region: "Читинская обл.",
    city: "Аксеново-Зиловское",
  },
  {
    region: "Читинская обл.",
    city: "Акша",
  },
  {
    region: "Читинская обл.",
    city: "Александровский Завод",
  },
  {
    region: "Читинская обл.",
    city: "Амазар",
  },
  {
    region: "Читинская обл.",
    city: "Арбагар",
  },
  {
    region: "Читинская обл.",
    city: "Атамановка",
  },
  {
    region: "Читинская обл.",
    city: "Балей",
  },
  {
    region: "Читинская обл.",
    city: "Борзя",
  },
  {
    region: "Читинская обл.",
    city: "Букачача",
  },
  {
    region: "Читинская обл.",
    city: "Газимурский Завод",
  },
  {
    region: "Читинская обл.",
    city: "Давенда",
  },
  {
    region: "Читинская обл.",
    city: "Дарасун",
  },
  {
    region: "Читинская обл.",
    city: "Дровяная",
  },
  {
    region: "Читинская обл.",
    city: "Дульдурга",
  },
  {
    region: "Читинская обл.",
    city: "Жиндо",
  },
  {
    region: "Читинская обл.",
    city: "Забайкальск",
  },
  {
    region: "Читинская обл.",
    city: "Итака",
  },
  {
    region: "Читинская обл.",
    city: "Калга",
  },
  {
    region: "Читинская обл.",
    city: "Карымское",
  },
  {
    region: "Читинская обл.",
    city: "Кличка",
  },
  {
    region: "Читинская обл.",
    city: "Ключевский",
  },
  {
    region: "Читинская обл.",
    city: "Кокуй",
  },
  {
    region: "Читинская обл.",
    city: "Краснокаменск",
  },
  {
    region: "Читинская обл.",
    city: "Красный Чикой",
  },
  {
    region: "Читинская обл.",
    city: "Кыра",
  },
  {
    region: "Читинская обл.",
    city: "Моготуй",
  },
  {
    region: "Читинская обл.",
    city: "Могоча",
  },
  {
    region: "Читинская обл.",
    city: "Нерчинск",
  },
  {
    region: "Читинская обл.",
    city: "Нерчинский Завод",
  },
  {
    region: "Читинская обл.",
    city: "Нижний Часучей",
  },
  {
    region: "Читинская обл.",
    city: "Оловянная",
  },
  {
    region: "Читинская обл.",
    city: "Первомайский",
  },
  {
    region: "Читинская обл.",
    city: "Петровск-Забайкальский",
  },
  {
    region: "Читинская обл.",
    city: "Приаргунск",
  },
  {
    region: "Читинская обл.",
    city: "Сретенск",
  },
  {
    region: "Читинская обл.",
    city: "Тупик",
  },
  {
    region: "Читинская обл.",
    city: "Улеты",
  },
  {
    region: "Читинская обл.",
    city: "Хилок",
  },
  {
    region: "Читинская обл.",
    city: "Чара",
  },
  {
    region: "Читинская обл.",
    city: "Чернышевск",
  },
  {
    region: "Читинская обл.",
    city: "Чита",
  },
  {
    region: "Читинская обл.",
    city: "Шелопугино",
  },
  {
    region: "Читинская обл.",
    city: "Шилка",
  },
  {
    region: "Чувашия",
    city: "Алатырь",
  },
  {
    region: "Чувашия",
    city: "Аликово",
  },
  {
    region: "Чувашия",
    city: "Батырева",
  },
  {
    region: "Чувашия",
    city: "Буинск",
  },
  {
    region: "Чувашия",
    city: "Вурнары",
  },
  {
    region: "Чувашия",
    city: "Ибреси",
  },
  {
    region: "Чувашия",
    city: "Канаш",
  },
  {
    region: "Чувашия",
    city: "Киря",
  },
  {
    region: "Чувашия",
    city: "Комсомольское",
  },
  {
    region: "Чувашия",
    city: "Красноармейское",
  },
  {
    region: "Чувашия",
    city: "Красные Четаи",
  },
  {
    region: "Чувашия",
    city: "Кугеси",
  },
  {
    region: "Чувашия",
    city: "Мариинский Посад",
  },
  {
    region: "Чувашия",
    city: "Моргауши",
  },
  {
    region: "Чувашия",
    city: "Новочебоксарск",
  },
  {
    region: "Чувашия",
    city: "Порецкое",
  },
  {
    region: "Чувашия",
    city: "Урмары",
  },
  {
    region: "Чувашия",
    city: "Цивильск",
  },
  {
    region: "Чувашия",
    city: "Чебоксары",
  },
  {
    region: "Чувашия",
    city: "Шемурша",
  },
  {
    region: "Чувашия",
    city: "Шумерля",
  },
  {
    region: "Чувашия",
    city: "Ядрин",
  },
  {
    region: "Чувашия",
    city: "Яльчики",
  },
  {
    region: "Чувашия",
    city: "Янтиково",
  },
  {
    region: "Чукотский АО",
    city: "Анадырь",
  },
  {
    region: "Чукотский АО",
    city: "Билибино",
  },
  {
    region: "Ямало-Ненецкий АО",
    city: "Губкинский",
  },
  {
    region: "Ямало-Ненецкий АО",
    city: "Заполярный",
  },
  {
    region: "Ямало-Ненецкий АО",
    city: "Муравленко",
  },
  {
    region: "Ямало-Ненецкий АО",
    city: "Надым",
  },
  {
    region: "Ямало-Ненецкий АО",
    city: "Новый Уренгой",
  },
  {
    region: "Ямало-Ненецкий АО",
    city: "Ноябрьск",
  },
  {
    region: "Ямало-Ненецкий АО",
    city: "Пуровск",
  },
  {
    region: "Ямало-Ненецкий АО",
    city: "Салехард",
  },
  {
    region: "Ямало-Ненецкий АО",
    city: "Тарко",
  },
  {
    region: "Ярославская обл.",
    city: "Андропов",
  },
  {
    region: "Ярославская обл.",
    city: "Берендеево",
  },
  {
    region: "Ярославская обл.",
    city: "Большое Село",
  },
  {
    region: "Ярославская обл.",
    city: "Борисоглебский",
  },
  {
    region: "Ярославская обл.",
    city: "Брейтово",
  },
  {
    region: "Ярославская обл.",
    city: "Бурмакино",
  },
  {
    region: "Ярославская обл.",
    city: "Варегово",
  },
  {
    region: "Ярославская обл.",
    city: "Волга",
  },
  {
    region: "Ярославская обл.",
    city: "Гаврилов Ям",
  },
  {
    region: "Ярославская обл.",
    city: "Данилов",
  },
  {
    region: "Ярославская обл.",
    city: "Любим",
  },
  {
    region: "Ярославская обл.",
    city: "Мышкино",
  },
  {
    region: "Ярославская обл.",
    city: "Некрасовское",
  },
  {
    region: "Ярославская обл.",
    city: "Новый Некоуз",
  },
  {
    region: "Ярославская обл.",
    city: "Переславль-Залесский",
  },
  {
    region: "Ярославская обл.",
    city: "Пошехонье-Володарск",
  },
  {
    region: "Ярославская обл.",
    city: "Ростов",
  },
  {
    region: "Ярославская обл.",
    city: "Рыбинск",
  },
  {
    region: "Ярославская обл.",
    city: "Тутаев",
  },
  {
    region: "Ярославская обл.",
    city: "Углич",
  },
  {
    region: "Ярославская обл.",
    city: "Ярославль",
  },
];
