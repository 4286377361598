import { useEffect, useState } from "react";
import Style from "./Chats.module.scss";
import { chatApi } from "../../../utils/ChatApi";
import ButtonsNavigation from "../../Buttons/ButtonsNavigation/ButtonsNavigation";
import ErrorBox from "../../ErrorBox/ErrorBox";
import ErrServer from "../../ErrServer/ErrServer";
import Button from "../../Buttons/Button/Button";
import ModulContainer from "../../Moduls/ModulContainer/ModulContainer";
import FormAddChat from "../../FormAddChat/FormAddChat";
import { cutTime } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import ModulePreloader from "../../Moduls/ModulePreloader/ModulePreloader";
import ModulConfirmation from "../../Moduls/ModulConfirmation/ModulConfirmation";
import { useSelector } from "react-redux";
import {
  isStatusModule,
  selectModuleConfirmation,
} from "../../../redax/slices/moduleConfirmationSlice";
import { useDispatch } from "react-redux";
import Alert from "../../Moduls/Alert/Alert";
import { selectUser } from "../../../redax/slices/userSlice";
import { selectAuth } from "../../../redax/slices/authSlice";

export type Users = {
  idUser: string;
  userName: string;
  _id: string;
};

export type Messages = {
  message: string;
  userName: string;
  createdAt: string;
  _id: string;
};

export type Chats = {
  title: string;
  createdAt: string;
  messages: Messages[];
  users: Users[];
  _id: string;
};

export default function Chats() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [chats, isChats] = useState<Chats[]>();
  const [chatId, isChatId] = useState("");
  const [preloader, isPreloader] = useState(true);
  const [preloaderDelete, isPreloaderDelete] = useState(false);
  const [textErr, isTextErr] = useState("Нет не одного созданного чата");
  const [formAddChat, isFormAddChat] = useState(false);
  const [stateAlert, isStateAlert] = useState(false);
  const [textAnswer, isTextAnswer] = useState("");
  const { statusModule } = useSelector(selectModuleConfirmation);
  const { user } = useSelector(selectUser);
  const { token } = useSelector(selectAuth);

  const getChats = (): void => {
    token &&
      chatApi
        .getAllChats(token)
        .then((res: Chats[]) => {
          isChats(res.reverse());
        })
        .catch((err) => {
          isTextErr(`При загрузке данных на сервере произошла ошибка: ${err}`);
          console.log(err);
        })
        .finally(() => {
          isPreloader(false);
        });
  };

  useEffect(() => {
    getChats();
  }, []);

  const openWindow = (): void => {
    isFormAddChat(true);
  };

  const entryChat = (idChat: string): void => {
    localStorage.setItem("idChat", idChat);
    navigate("/chat");
  };

  const deleteChat = (id: string): void => {
    isPreloaderDelete(true);

    chatApi
      .deleteChat(id)
      .then(() => {
        chats?.splice(
          chats?.findIndex((elem) => elem._id === id),
          1
        );
      })
      .catch((err) => {
        isTextAnswer(`При удалении чата произошла ошибка ${err}`);
        isStateAlert(true);
        setTimeout(() => isStateAlert(false), 1700);
        console.log(err);
      })
      .finally(() => {
        isPreloaderDelete(false);
      });
  };

  return (
    <div>
      <div className={Style.chat__buttons}>
        <ButtonsNavigation page="/" text="Назад" />
        <Button text="Создать чат" onClick={openWindow} />
      </div>
      <div className={Style.chats}>
        <h2 className={Style.chats__title}>Чаты</h2>
        <ul className={Style.chat__list}>
          {!preloader ? (
            chats && chats.length > 0 ? (
              chats.map((elem) => (
                <li
                  className={Style.chat__card}
                  key={elem._id}
                  onClick={() => entryChat(elem._id)}
                >
                  <h4 className={Style.chat__card_title}>{elem.title}</h4>
                  <span className={Style.chat__card_date}>
                    {cutTime(elem.createdAt)}
                  </span>
                  {user?.admin && (
                    <button
                      onClick={(event) => {
                        isChatId(elem._id);
                        event.stopPropagation();
                        dispatch(isStatusModule(true));
                      }}
                      className={Style.chat__delete}
                    >
                      delete
                    </button>
                  )}
                </li>
              ))
            ) : (
              <ErrorBox>
                <ErrServer textErr={textErr} />
              </ErrorBox>
            )
          ) : (
            <ModulePreloader text="Загрузка" />
          )}
        </ul>
      </div>
      {formAddChat && (
        <ModulContainer clickOverly={() => isFormAddChat(false)}>
          <FormAddChat close={isFormAddChat} addChats={getChats} />
        </ModulContainer>
      )}
      {preloaderDelete && <ModulePreloader text="Удаление..." />}
      {statusModule && (
        <ModulConfirmation text="Удалить?" confirm={() => deleteChat(chatId)} />
      )}
      <Alert text={textAnswer} stateAlert={stateAlert} />
    </div>
  );
}
