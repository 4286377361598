import React from "react";

import { Routes, Route } from "react-router-dom";

import Home from "./components/Home/Home";
import ForumTopics from "./components/ForumTopics/ForumTopics";
import Topic from "./components/ForumTopics/Topic/Topic";
import MyPage from "./components/Pages/MyPage/MyPage";
import FormEditUser from "./components/Pages/FormEditUser/FormEditUser";
import NotFoundPage from "./components/Pages/NotFoundPage/NotFoundPage";
import { useSelector } from "react-redux";
import { useAppDispatch } from "./redax/store";
import { killAllStateAuth, selectAuth } from "./redax/slices/authSlice";
import PageAdmin from "./components/Pages/PageAdmin/PageAdmin";
import ListUser from "./components/Pages/ListUsers/ListUsers";

import {
  selectUser,
  fetchGetUser,
  killAllStateUser,
} from "./redax/slices/userSlice";
import PageUser from "./components/Pages/PageUser/PageUser";
import Chats from "./components/Pages/Chats/Chats";
import Chat from "./components/Pages/Chat/Chat";
import { killAllStateFormValidetion } from "./redax/slices/formValidetionSlice";
import { killAllStateTopic } from "./redax/slices/topicSlice";
import { killPaginationState } from "./redax/slices/paginationSlice";

function App() {
  const dispatch = useAppDispatch();

  const { token } = useSelector(selectAuth);

  const { admin } = useSelector(selectUser);

  React.useEffect(() => {
    if (token) {
      dispatch(fetchGetUser({ token })).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          localStorage.clear();
          dispatch(killAllStateAuth());
          dispatch(killAllStateFormValidetion());
          dispatch(killAllStateTopic());
          dispatch(killAllStateUser());
          dispatch(killPaginationState());
        }
      });
    }
  }, [token]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/topics" element={<ForumTopics />} />
      <Route path="/topic" element={<Topic />} />
      <Route path="/my-page" element={token ? <MyPage /> : <Home />} />
      <Route path="/edit-user" element={token ? <FormEditUser /> : <Home />} />
      <Route path="/chats" element={token ? <Chats /> : <Home />} />
      <Route path="/chat" element={token ? <Chat /> : <Home />} />
      <Route path="/admin" element={admin ? <PageAdmin /> : <Home />} />
      <Route path="/list-user" element={admin ? <ListUser /> : <Home />} />
      <Route path="/user" element={<PageUser />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
