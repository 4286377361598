import Style from "./Button.module.scss";

interface ButtonDeleteProps {
  text: string;
  onClick: () => void;
}

export default function Button({ text, onClick }: ButtonDeleteProps) {
  return (
    <div onClick={() => onClick()} className={Style.button}>
      {text}
    </div>
  );
}
