import React, { useEffect, useState } from "react";
import webSocket from "../../../utils/socket";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../redax/slices/userSlice";
import { Users, Chats, Messages } from "../Chats/Chats";
import Style from "./Chat.module.scss";
import ButtonsNavigation from "../../Buttons/ButtonsNavigation/ButtonsNavigation";
import { cutTime } from "../../../utils/utils";
import TextInteractionForm from "../../TextInteractionForm/TextInteractionForm";
import ButtonSubmit from "../../Buttons/ButtonSubmit/ButtonSubmit";
import { chatApi } from "../../../utils/ChatApi";
import ModulConfirmation from "../../Moduls/ModulConfirmation/ModulConfirmation";
import {
  isStatusModule,
  selectModuleConfirmation,
} from "../../../redax/slices/moduleConfirmationSlice";

type SetUsersAnswer = {
  answer: Users[];
  status: boolean;
};

type SetMessageAnswer = {
  answer: Messages[] | string;
  delete?: boolean;
  status: boolean;
};

type SetChatAnswer = {
  answer: Chats;
  status: boolean;
};

export default function Chat() {
  const dispatch = useDispatch();
  const { statusModule } = useSelector(selectModuleConfirmation);
  const [users, isUsers] = useState<Users[]>();
  const [message, isMessage] = useState("");
  const [messages, isMessags] = useState<Messages[]>();
  const [title, isTitle] = useState("");
  const [textErr, isTextErr] = useState("");
  const { user } = useSelector(selectUser);
  const idChat = localStorage.getItem("idChat");
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const chatMessageRef = React.useRef<HTMLUListElement>(null);
  const [scroll, isScroll] = useState(false);
  const [heightMessage, isHeightMessage] = useState<number>();
  const [idMessage, isIdMessage] = useState<string>();

  const setUsers = (users: SetUsersAnswer): void => {
    isUsers(users.answer);
  };

  const setMessage = (message: SetMessageAnswer): void => {
    if (message.status && Array.isArray(message.answer)) {
      isMessags(message.answer);
      return;
    }
    if (typeof message.answer === "string") {
      if (message.delete) {
        alert(`При удалении сообщения произошла ошибка: ${message.answer}`);
        return;
      }
      isTextErr(`При отправке сообщения произошла ошибка: ${message.answer}`);
      setTimeout(() => isTextErr(""), 1600);
    }
  };

  const setChat = (chat: SetChatAnswer): void => {
    isMessags(chat.answer.messages);
    isTitle(chat.answer.title);
  };

  useEffect(() => {
    if (chatMessageRef.current && messages) {
      isHeightMessage(chatMessageRef.current.scrollHeight / messages?.length);
    }
    if (!scroll) {
      scrollForm();
    }
  }, [messages]);

  React.useEffect(() => {
    if (user && idChat) {
      const obj = {
        id: idChat,
        userName: user.name,
        idUser: user._id,
      };
      webSocket.emit("ROOM:JOIN", obj);
    }
    if (scroll) {
      webSocket.removeAllListeners("ROOM:NEW_MESSAGE");
      webSocket.removeAllListeners("ROOM:SET_CHAT");
    } else {
      webSocket.on("ROOM:SET_CHAT", setChat);
      webSocket.on("ROOM:SET_USERS", setUsers);
      webSocket.on("ROOM:NEW_MESSAGE", setMessage);
    }

    return () => {
      if (user?._id && idChat) {
        webSocket.emit("ROOM:EXIT", {
          id: idChat,
          idUser: user._id,
        });
      }
    };
  }, [user, scroll]);

  const scrollForm = (): void => {
    if (chatMessageRef.current) {
      chatMessageRef.current.scrollTop = chatMessageRef.current.scrollHeight;
    }
  };

  const onSabmit = (
    idChat: string | null,
    userName: string | null,
    message: string
  ) => {
    isScroll(false);
    const regex = /[^\s]+/;
    const result = regex.test(message);
    if (!result) {
      isTextErr("Минимальная длинна сообщения должна быть 1 символ");
      setTimeout(() => isTextErr(""), 1500);
      return;
    }
    if (idChat && userName) {
      const objMessage = { chatId: idChat, userName, text: message };
      webSocket.emit("ROOM:NEW_MESSAGE", objMessage);
      isMessage("");
    }
  };

  const deleteMessage = (messageId: string, chatId: string) => {
    const objMessage = { messageId, chatId };
    webSocket.emit("ROOM:DELETE_MESSAGE", objMessage);
  };

  const scrollChat = (evt: React.UIEvent<HTMLUListElement>) => {
    const document = evt.target as HTMLUListElement;
    const allHeight = Math.floor(document.scrollHeight);
    const startDocument = Math.floor(document.scrollTop);
    const window = Math.floor(document.clientHeight);
    let number;
    if (messages) {
      number = messages?.length + 50;
    }
    if (
      allHeight === startDocument + window ||
      allHeight === startDocument + window + 1 ||
      allHeight === startDocument + window - 1
    ) {
      isScroll(false);
    } else {
      isScroll(true);
    }
    if (startDocument === 0 && number) {
      idChat &&
        chatApi
          .getMessages(-Math.abs(number), idChat)
          .then((res: Messages[]) => {
            isMessags(res);
            setTimeout(() => {
              if (messages) {
                const numberMessages = res.length - messages?.length;
                if (chatMessageRef.current && heightMessage) {
                  chatMessageRef.current.scrollTop =
                    heightMessage * numberMessages;
                }
              }
            });
          })
          .catch((err) => {
            console.log(err);
          });
    }
  };

  return (
    <div className={Style.chat}>
      <div className={Style.chat__buttons}>
        <ButtonsNavigation page="/chats" text="Назад" />
        <ButtonsNavigation page="/" text="На главную" />
        <h1 className={Style.chat__title}>{title}</h1>
      </div>
      <ul className={Style.chat__users}>
        {users &&
          users.map((elem) => (
            <li className={Style.chat__user} key={elem._id + elem._id}>
              {elem.userName}
            </li>
          ))}
      </ul>
      <div className={Style.chat__conteiner}>
        <ul
          onScroll={(evt) => scrollChat(evt)}
          ref={chatMessageRef}
          className={Style.chat__messages}
        >
          {messages &&
            messages.map((elem) => (
              <li
                className={Style.chat__message_box}
                key={elem.createdAt + elem.userName}
              >
                <span className={Style.chat__message_user}>
                  {elem.userName}
                </span>
                <p>{elem.message}</p>
                <span className={Style.chat__message_date}>
                  {cutTime(elem.createdAt)}
                </span>
                {user?.admin && (
                  <button
                    onClick={() => {
                      isIdMessage(elem._id);
                      dispatch(isStatusModule(true));
                    }}
                    className={Style.delete}
                  >
                    delete
                  </button>
                )}
              </li>
            ))}
        </ul>
        <form
          className={Style.chat__form}
          onSubmit={(evt) => {
            evt.preventDefault();
            user && onSabmit(idChat, user.name, message);
          }}
        >
          {" "}
          <textarea
            cols={40}
            rows={3}
            maxLength={500}
            minLength={1}
            ref={inputRef}
            value={message}
            onChange={(evt) => isMessage(evt.target.value)}
          />
          <ButtonSubmit
            valid={true}
            showPreloader={false}
            textAnswerRequest={""}
            text={"отправить"}
          />
          <div className={Style.error}>
            <TextInteractionForm text={textErr} />
          </div>
        </form>
      </div>
      {statusModule && (
        <ModulConfirmation
          text="Удалить?"
          confirm={() =>
            idChat && idMessage && deleteMessage(idMessage, idChat)
          }
        />
      )}
    </div>
  );
}
