import React, { ReactNode } from "react";
import Style from "./ErrorBox.module.scss";

type ErrorBoxProps = {
  children: ReactNode;
};

export default function ErrorBox({ children }: ErrorBoxProps) {
  return <div className={Style.errors__box}>{children}</div>;
}
