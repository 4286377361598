import Style from "./UserCard.module.scss";
import { useNavigate } from "react-router-dom";
import { URL_SERVER } from "../../utils/Constants";
import { User } from "../../redax/slices/userSlice";

export default function UserCard({ user }: { user: User }) {
  const navigation = useNavigate();

  const openPageUser = (id: string): void => {
    localStorage.setItem("CurrentUserId", id);
    navigation("/user");
  };

  return (
    <div onClick={() => openPageUser(user._id)} className={Style.info_user}>
      <img
        className={Style.foto}
        src={
          user.avatar
            ? `${URL_SERVER}/${user.avatar}`
            : "./images/no_foto_1.png"
        }
        alt="аватарка"
      />
      <h3 className={Style.name}>{user.name}</h3>
      <p>{`(${user.gender})${user.age}`}</p>
      <p>{user.town}</p>
      <p className={Style.email}>{user.email}</p>
    </div>
  );
}
