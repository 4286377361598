import { useState } from "react";
import Style from "./FormAddChat.module.scss";
import Button from "../Buttons/Button/Button";

import { chatApi } from "../../utils/ChatApi";
import BottonSubmit from "../Buttons/ButtonSubmit/ButtonSubmit";

type PropsAddChat = {
  close: React.Dispatch<React.SetStateAction<boolean>>;
  addChats?: () => void;
};

export default function FormAddChat({ close, addChats }: PropsAddChat) {
  const [nameChat, isNameChat] = useState("");
  const [textErr, isTextErr] = useState("");
  const [preloader, isPreloader] = useState(false);

  const addChat = (title: string) => {
    isPreloader(true);
    const regex = /[^\s]+/;
    const result = regex.test(title);
    if (!result) {
      isTextErr("Минимальная длинна сообщения должна быть 2 символа");
      setTimeout(() => isTextErr(""), 1500);
      //isPreloader(false);
      return;
    }
    chatApi
      .addNewChat({ title })
      .then((res) => {
        //console.log(res);
        addChats && addChats();
        isNameChat("");
        close(false);
      })
      .catch((err) => {
        isTextErr(
          `При создании темы на сервевре произошла ошибка: ${err}`
        );
        setTimeout(() => isTextErr(""), 1500);
        //console.log(err);
      })
      .finally(() => {
        isPreloader(false);
      });
  };

  return (
    <form
      className={Style.add_chat}
      onSubmit={(evt) => {
        evt.preventDefault();
        addChat(nameChat);
      }}
    >
      <label>Название:</label>
      <input
        value={nameChat}
        onChange={(evt) => isNameChat(evt.target.value)}
        maxLength={30}
        minLength={2}
      ></input>
      <div className={Style.add_chat__button}>
        <BottonSubmit
          valid={true}
          showPreloader={preloader}
          textAnswerRequest={textErr}
          text="Создать"
        />
      </div>
    </form>
  );
}
